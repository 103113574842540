<script>
  import AppointmentIframe from "./AppointmentIframe.vue";
  import ImageCarousel from "./ImageCarousel.vue";
  import HoursPopover from "./HoursPopover.vue";
  import _ from "lodash";
  import Event from "./Event.vue";

  export default {
    components: { AppointmentIframe, ImageCarousel, HoursPopover, Event },
    name: "BusinessCard",
    props: {
      business: {
        required: true,
        type: Object,
        validator: businessValidator,
      },
      isSelected: {
        required: false,
        type: Boolean,
        default: false,
      },
      closureMessage: {
        required: true,
        type: String,
      },
      pendingOpeningMessage: {
        required: true,
        type: String,
      },
      isLocatorEmbeded: {
        require: true,
        type: Boolean,
      },
      businessCardShowcaseFlags: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        imgCarouselVisible: false,
      };
    },
    methods: {
      handleSelect(location) {
        this.$emit("handleCardSelection", location);
      },
      showBusinessServicesList(business) {
        return (
          this.businessCardShowcaseFlags.show_services_list &&
          business.services_list.length > 0
        );
      },
    },
    computed: {
      showBusinessAttributes() {
        return !this.businessCardShowcaseFlags.show_services_list;
      },
      locationAddress() {
        return this.business.address_text_lines.join(", ");
      },
      locationImages() {
        return this.business.get_images_medialinks.map(
          ({ uri, alt_text, caption }) => ({
            uri: uri,
            alt: alt_text
              ? alt_text
              : `Picture of ${this.business.display_name}`,
            caption,
          })
        );
      },
      showDistance() {
        // Distance will be shown as default or if user creates a user store locator config
        // and uncheck display_distance flag
        return (
          this.business.get_storefront_config.display_distance ||
          _.isEmpty(this.business.get_storefront_config)
        );
      },
      showBusinessCardImage() {
        return (
          (this.business.get_storefront_config.display_business_card_image ||
            _.isEmpty(this.business.get_storefront_config)) &&
          this.business.main_image_uri_medialink
        );
      },
      roundedDistance() {
        return parseFloat(this.business.distance).toFixed(1);
      },
      htmlHours() {
        // stringHrs is a list of lists for the days of the week, where the first day is today.
        // Ex: [[[<today-weekday>, <htmlhours>]], [[<tomorrow-weekday>, <htmlhours>]],...]
        // See lmbrowser.models.Business._build_hours_list
        const todaysOpenData = this.business?.all_opening_hours?.stringHrs[0];
        // Use the first entry for today's data, retuned as [[<weekday>, <htmlhours>]], and extract just the HTML hours.
        if (todaysOpenData) {
          return todaysOpenData[0][1];
        }
        return null;
      },
      openStatusText() {
        if (this.business.all_opening_hours.isOpen === null) {
          return "";
        } else {
          return this.business.all_opening_hours.isOpen ? "Open" : "Open today";
        }
      },
      featuredButtonDisplay() {
        // featured button will be shown by default or when creating a new
        // storelocatorconfig object. User have the ability to turn off the
        // featured button in storelocatorconfig
        // backend will return the appropriate link (BD or PL pge)
        if (
          "featured_button_link" in this.business.get_storefront_config &&
          this.business.get_storefront_config.featured_button_link
        ) {
          return "CUSTOM";
        } else if (
          // no storelocatorconfig found, default to in-stock products button
          _.isEmpty(this.business.get_storefront_config) &&
          this.business.business_link
        ) {
          return "DEFAULT";
        }
        return undefined;
      },
      businessInfoDisplayed() {
        if (
          this.businessCardShowcaseFlags.show_address ||
          this.businessCardShowcaseFlags.show_phone_number ||
          this.businessCardShowcaseFlags.show_open_status_and_hours
        ) {
          return true;
        }
        return false;
      },
      showLocatorFeaturedButton() {
        const infoPageLocatorFeaturedBtn = document.getElementById(
          "show-locator-featured-button"
        );
        // if show-locator-featured-button appears in a meta tag and the content is False, hide the featured button
        if (infoPageLocatorFeaturedBtn?.getAttribute("content") === "False") {
          return false;
        } else {
          return true;
        }
      },
    },
  };
  function businessValidator(obj) {
    const expectedFields = [
      "display_name",
      "lat",
      "lon",
      "distance",
      "address_text_lines",
      "all_opening_hours",
      "get_directions_link",
      "business_link",
      "business_attributes_showcase",
      "contact_context",
      "business_type_text",
    ];
    return expectedFields.every((field) => field in obj);
  }
</script>

<template>
  <div
    @click="handleSelect(business)"
    class="business-card"
    :class="{ selected: isSelected }"
    data-visual-test="locator-business-list-biz-card"
  >
    <div>
      <div
        class="business-header__text"
        :class="{
          'b-primary': business.business_type_css_treatment === '1',
          'b-secondary': business.business_type_css_treatment === '2',
          'b-tertiary': business.business_type_css_treatment === '3',
        }"
      >
        <span
          v-if="business.business_type_highlight_text"
          class="business-header__highlight-text"
        >
          {{ business.business_type_highlight_text }}
        </span>
        <div class="business-header__meta">
          <span v-if="business.business_type_text" class="store-type">
            {{ business.business_type_text }}
          </span>
          <span class="distance" v-if="showDistance">
            {{ roundedDistance }} mi
          </span>
        </div>
      </div>
      <div class="business-header">
        <h2>{{ business.display_name }}</h2>
        <div
          v-if="business.get_storefront_config.display_business_group"
          class="business-card__business-group-name"
        >
          {{ business.business_group.display_name }}
        </div>
        <div class="business-header__business-main">
          <el-dialog :visible.sync="imgCarouselVisible" width="95%">
            <image-carousel :images="locationImages" />
          </el-dialog>
          <button
            class="business-card__photo"
            @click="imgCarouselVisible = !imgCarouselVisible"
            v-if="showBusinessCardImage"
            data-ga="image-carousel"
            data-dl2="Locator-Image-Carousel"
            data-dl2-e201="View-Image"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
          >
            <img
              :src="business.main_image_uri_medialink"
              :alt="'Image gallery for ' + business.display_name"
            />
          </button>
          <div class="business-card__attributes" v-if="showBusinessAttributes">
            <span
              v-for="attribute in business.business_attributes_showcase"
              :key="attribute.url_name"
            >
              {{ attribute.display_name }}
            </span>
          </div>
          <div
            class="business-card__services"
            v-else-if="showBusinessServicesList(business)"
          >
            <div
              v-if="businessCardShowcaseFlags.store_locator_services_list_title"
              class="business-card__services__header"
            >
              {{ businessCardShowcaseFlags.store_locator_services_list_title }}
            </div>
            <div class="business-card__services__list">
              <div v-for="service in business.services_list" :key="service">
                {{ service }}
              </div>
            </div>
          </div>
        </div>
        <event
          v-if="Object.keys(business.events_showcase).length"
          :eventsShowcase="business.events_showcase"
          :businessLink="`${business.business_link}#eventShowcaseCarousel`"
        ></event>
      </div>
      <hr :class="[businessInfoDisplayed ? 'margin-15' : 'margin-10']" />
      <div class="business-card__business-info">
        <div v-if="business.get_storefront_config.description">
          {{ business.get_storefront_config.description }}
        </div>
        <div class="font-size-14" v-if="business.stock_indicator">
          <strong :class="business.stock_indicator.stock_img">
            {{ business.stock_indicator.stock_description }},
          </strong>
          {{ business.stock_indicator.stock_details }}
        </div>
        <template
          v-if="!business.permanently_closed && !business.pending_opening"
        >
          <div
            :class="{
              'business-card__data-line':
                businessCardShowcaseFlags.show_open_status_and_hours,
            }"
          >
            <!-- Location open status/hours -->
            <hours-popover
              :business="business"
              v-if="businessCardShowcaseFlags.show_open_status_and_hours"
            >
              <template v-slot:hour>
                <span v-if="htmlHours == 'Closed'">
                  <strong
                    class="business-card__hours__status"
                    v-html="htmlHours"
                  ></strong>
                </span>
                <span
                  v-else-if="htmlHours == 'Temporarily Closed'"
                  class="business-card__hours"
                >
                  <img
                    class="business-card__closure-icon"
                    src="https://lmbucket0.s3.amazonaws.com/media/global_configs/Icon-UI-Warning_Outlined.png"
                    alt=""
                  />
                  <span v-html="htmlHours"></span>
                </span>
                <span v-else class="business-card__hours">
                  <strong class="business-card__hours__status">
                    {{ openStatusText }}
                  </strong>
                  <span v-html="htmlHours"></span>
                </span>
              </template>
            </hours-popover>
          </div>
          <!-- Location address link -->
          <a
            v-if="
              business.address_text_lines.length &&
              businessCardShowcaseFlags.show_address
            "
            :href="business.get_directions_link"
            ref="address-text"
            class="business-card__data-line"
            target="_blank"
            rel="noopener noreferrer"
            data-ga="Navigate-To-Store-Locator"
            data-ga-event="hard"
            :data-ga-2="business.external_store_code"
            :data-ga-9="business.url_name"
            :data-ga-10="business.address_postcode"
            :data-ga-11="business.locale.name"
            data-dl2="Locator-Card-Text-Get-Directions"
            data-dl2-e201="Get-Directions"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
            :aria-label="'Get directions to ' + locationAddress"
          >
            <span>
              {{ locationAddress }}
            </span>
          </a>
          <a
            v-if="
              business.contact_context.business_phone_text &&
              businessCardShowcaseFlags.show_phone_number
            "
            class="business-card__data-line"
            ref="phone-text"
            :href="'tel:' + business.contact_context.business_phone_raw"
            target="_blank"
            rel="noopener noreferrer"
            data-ga="Call-Store-Locator"
            data-ga-event="hard"
            :data-ga-2="business.external_store_code"
            :data-ga-9="business.url_name"
            :data-ga-10="business.address_postcode"
            :data-ga-11="business.locale.name"
            data-dl2="Locator-Card-Text-Call"
            data-dl2-e201="Call"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
            :aria-label="'Call ' + business.contact_context.business_phone_text"
          >
            <span>{{ business.contact_context.business_phone_text }}</span>
          </a>
        </template>
        <div
          v-else-if="business.pending_opening && !business.permanently_closed"
          class="business-card__data-line"
        >
          <strong class="business-card__hours__status pending-opening">
            <img
              class="business-card__closure-icon"
              src="https://lmbucket0.s3.amazonaws.com/media/global_configs/Icon-UI-Warning_Outlined.png"
              alt=""
            />
            {{ pendingOpeningMessage }}
          </strong>
        </div>
        <div v-else class="business-card__data-line">
          <strong class="business-card__hours__status closed">
            <img
              class="business-card__closure-icon"
              src="https://lmbucket0.s3.amazonaws.com/media/global_configs/Icon-UI-Warning_Outlined.png"
              alt=""
            />
            {{ closureMessage }}
          </strong>
        </div>
        <!-- display address note here -->
        <div class="business-card__address-note" v-if="business.address_note">
          <span>
            {{ business.address_note }}
          </span>
        </div>
      </div>
    </div>
    <template v-if="!business.permanently_closed && !business.pending_opening">
      <div class="business-card__ctas" v-if="business.get_storefront_buttons">
        <template v-for="button in business.get_storefront_buttons">
          <div
            class="business-card__ctas__phone"
            :key="button.text"
            v-if="
              business.contact_context.business_phone_raw &&
              button.link_type === 'PHONE'
            "
          >
            <a
              :href="'tel:' + business.contact_context.business_phone_raw"
              :target="button.open_in_new_tab ? '_blank' : false"
              rel="noopener noreferrer"
              data-ga="Call-Store-Locator"
              data-ga-event="hard"
              :data-ga-2="business.external_store_code"
              :data-ga-9="business.url_name"
              :data-ga-10="business.address_postcode"
              :data-ga-11="business.locale.name"
              data-dl2="Locator-Card-Button-Call"
              data-dl2-e201="Call"
              data-dl2-e203="y"
              data-dl2-e210="Business"
              :data-dl2-e211="business.dl2_click_id"
              :aria-label="'Call ' + business.display_name"
            >
              <span
                class="business-card__call-icon vsl-icon"
                aria-hidden="true"
              ></span>
              <span class="business-card__ctas__text">{{ button.text }}</span>
            </a>
          </div>
          <div
            class="business-card__ctas__directions"
            :key="button.text"
            v-if="
              business.get_directions_link && button.link_type === 'DIRECTIONS'
            "
          >
            <a
              :href="business.get_directions_link"
              :target="button.open_in_new_tab ? '_blank' : false"
              rel="noopener noreferrer"
              data-ga="Navigate-To-Store-Locator"
              data-ga-event="hard"
              :data-ga-2="business.external_store_code"
              :data-ga-9="business.url_name"
              :data-ga-10="business.address_postcode"
              :data-ga-11="business.locale.name"
              data-dl2="Locator-Card-Button-Get-Directions"
              data-dl2-e201="Get-Directions"
              data-dl2-e203="y"
              data-dl2-e210="Business"
              :data-dl2-e211="business.dl2_click_id"
            >
              <span
                class="business-card__direction-icon vsl-icon"
                aria-hidden="true"
              ></span>
              <span class="business-card__ctas__text">{{ button.text }}</span>
            </a>
          </div>
          <div
            class="business-card__ctas__appointment"
            :key="button.text"
            v-if="
              business.analytics_appointment_link &&
              button.link_type === 'APPOINTMENT'
            "
          >
            <appointment-iframe
              :appointmentLink="business.analytics_appointment_link"
              :business="business"
            >
              <span
                class="business-card__book-online-icon vsl-icon"
                aria-hidden="true"
              ></span>
              <span class="business-card__ctas__text">{{ button.text }}</span>
            </appointment-iframe>
          </div>
          <div
            class="business-card__ctas__custom"
            :key="button.text"
            v-if="button.link_type === 'CUSTOM'"
          >
            <a
              :href="button.url"
              :target="button.open_in_new_tab ? '_blank' : false"
              v-bind:[button.attribute_name_1]="button.attribute_value_1"
              v-bind:[button.attribute_name_2]="button.attribute_value_2"
              :aria-label="button.text"
            >
              <span
                :class="button.css_icon"
                aria-hidden="true"
                class="fa-icon"
              ></span>
              <span class="business-card__ctas__text">{{ button.text }}</span>
            </a>
          </div>
        </template>
      </div>
      <div class="business-card__ctas" v-else>
        <div
          v-if="business.contact_context.business_phone_raw"
          class="business-card__ctas__phone"
        >
          <a
            :href="'tel:' + business.contact_context.business_phone_raw"
            target="_blank"
            rel="noopener noreferrer"
            data-ga="Call-Store-Locator"
            data-ga-event="hard"
            :data-ga-2="business.external_store_code"
            :data-ga-9="business.url_name"
            :data-ga-10="business.address_postcode"
            :data-ga-11="business.locale.name"
            data-dl2="Locator-Card-Button-Call"
            data-dl2-e201="Call"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
            :aria-label="'Call ' + business.display_name"
          >
            <span
              class="business-card__call-icon vsl-icon"
              aria-hidden="true"
            ></span>
            <span class="business-card__ctas__text">Call</span>
          </a>
        </div>
        <div
          v-if="business.get_directions_link"
          class="business-card__ctas__directions"
        >
          <a
            :href="business.get_directions_link"
            target="_blank"
            rel="noopener noreferrer"
            data-ga="Navigate-To-Store-Locator"
            data-ga-event="hard"
            :data-ga-2="business.external_store_code"
            :data-ga-9="business.url_name"
            :data-ga-10="business.address_postcode"
            :data-ga-11="business.locale.name"
            data-dl2="Locator-Card-Button-Get-Directions"
            data-dl2-e201="Get-Directions"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
            :aria-label="'Directions to ' + business.display_name"
          >
            <span
              class="business-card__direction-icon vsl-icon"
              aria-hidden="true"
            ></span>
            <span class="business-card__ctas__text">Directions</span>
          </a>
        </div>
        <div
          v-if="business.analytics_appointment_link"
          class="business-card__ctas__appointment"
        >
          <appointment-iframe
            :appointmentLink="business.analytics_appointment_link"
            :business="business"
          >
            <span
              class="business-card__book-online-icon vsl-icon"
              aria-hidden="true"
            ></span>
            <span class="business-card__ctas__text">Appointment</span>
          </appointment-iframe>
        </div>
      </div>
      <div class="business-card__ctas">
        <template v-if="!isLocatorEmbeded">
          <a
            v-if="featuredButtonDisplay === 'CUSTOM'"
            :href="business.get_storefront_config.featured_button_link"
            target="_blank"
            class="featured-button"
            data-ga="locator-featured-button"
            :data-ga-2="business.external_store_code"
            :data-ga-9="business.url_name"
            :data-ga-10="business.address_postcode"
            :data-ga-11="business.locale.name"
            data-dl2="Locator-Featured-Button"
            data-dl2-e201="Custom-Button"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
            :aria-label="`${business.get_storefront_config.featured_button_text} ${business.display_name}`"
          >
            <span>
              {{ business.get_storefront_config.featured_button_text }}
            </span>
          </a>
          <a
            v-if="featuredButtonDisplay === 'DEFAULT'"
            :href="business.business_link"
            target="_blank"
            class="featured-button"
            data-ga="locator-featured-button"
            :data-ga-2="business.external_store_code"
            :data-ga-9="business.url_name"
            :data-ga-10="business.address_postcode"
            :data-ga-11="business.locale.name"
            data-dl2="Locator-Featured-Button"
            data-dl2-e201="Location-Link"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
            :aria-label="
              'Show Store Deals & Devices at ' + business.display_name
            "
          >
            <span>Show Store Deals & Devices</span>
          </a>
        </template>
        <template v-else>
          <a
            v-if="business.product_link && showLocatorFeaturedButton"
            :href="business.product_link"
            target="_blank"
            class="featured-button"
            data-ga="locator-featured-button"
            :data-ga-2="business.external_store_code"
            :data-ga-9="business.url_name"
            :data-ga-10="business.address_postcode"
            :data-ga-11="business.locale.name"
            data-dl2="Locator-Featured-Button"
            data-dl2-e201="Product-Link"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="business.dl2_click_id"
            :aria-label="'Shop in stock products at ' + business.display_name"
          >
            <span>
              View
              <i class="fa fa-chevron-right"></i>
            </span>
          </a>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @import "../styles/breakpoints";
  @import "../styles/variables";

  $selected-box-shadow: inset 0 0 0 2px $primary;

  .business-card {
    width: 95%;
    background: #ffffff;
    padding: 2.4rem;
    margin: 0px 10px 16px;
    cursor: pointer;

    @include media(">=tablet") {
      padding: 2.4rem 2rem;
    }

    &.selected {
      box-shadow: $selected-box-shadow;
    }

    &__business-info {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
    }

    &__attributes {
      color: $primary-text;
      font-size: 1.2rem;
      margin: 0 1rem 1rem 1rem;
      & > span:before {
        content: "• ";
      }
    }
    &__services {
      color: $primary-text;
      font-size: 1.2rem;
      &__header {
        margin: 0 1rem 0rem 1rem;
        font-weight: 600;
      }
      &__list {
        margin: 0 1rem 1rem 2rem;
        & > div:before {
          content: "• ";
        }
      }
    }
    &__hours {
      display: block;
      &__status {
        color: $primary;
        &.closed,
        &.pending-opening {
          color: #262626 !important;
          font-weight: 400;
        }
      }
    }
    &__data-line,
    &__address-note {
      color: $primary-text;
      font-size: 1.4rem;
      margin: 0 0 1rem;
      &:hover,
      &:focus {
        span {
          text-decoration: underline;
        }
        text-decoration: none !important;
      }
      > span {
        display: inline-block;
        font-weight: normal;
      }
    }

    &__photo {
      position: relative;
      border: none;
      padding: 0px;
      background-color: inherit;
      img {
        width: 90px;
        height: 55px;
        object-fit: cover;
      }
      &:hover {
        cursor: zoom-in;
      }
    }
    &__closure-icon {
      width: 20px;
      vertical-align: bottom;
    }
    &__ctas {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      flex-grow: 1;
      margin: 1.4rem 0;
      gap: 7px;
      & > * {
        flex: 1;
        border: 1px solid #dbdbdb;
        padding: 5px;
        text-align: center;
        &:hover {
          border: 1px solid $primary;
        }
      }
      a {
        text-decoration: none;
        text-align: center;
      }
      &__text {
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        color: $primary-text;
        display: flex;
        flex-direction: column;
      }
      .vsl-icon {
        display: block;
        color: $primary;
        padding: 0.5rem 1.6rem;
      }
      .fa-icon:before {
        font-size: 20px;
      }
      .featured-button {
        font-weight: bold;
        font-size: 1.4rem;
        text-align: center;
        padding: 1.2rem 1.6rem;
        color: $primary-text;
      }
    }
    &__business-group-name {
      color: #6a6a6a;
      margin-bottom: 0.8rem;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-top: -0.5rem;
    }
  }
  .business-header {
    h2 {
      margin-top: 0;
      font-size: 2rem;
    }
    &__highlight-text {
      // color in _vsl.scss
      font-weight: bold;
      display: block;
    }
    &__meta {
      display: flex;
      color: $secondary-text;
      font-size: 1.2rem;
      text-transform: uppercase;
      justify-content: space-between;
      .distance {
        letter-spacing: 0.3px;
        white-space: nowrap;
        text-align: right;
        padding-left: 2rem;
        flex: 1;
      }
    }

    &__business-main {
      display: flex;
      flex-direction: row;
    }
  }
  .fa-icon {
    font-family: "Font Awesome 5 Free";
    font-weight: 800;
    font-size: 24px;
    &::before {
      font-size: 24px;
    }
  }

  .address-icon {
    float: left;
  }
  :deep(*) {
    .el-dialog {
      box-shadow: none;
      background: rgba(black, 0.6);

      &__wrapper {
        cursor: default;
        background: rgba(black, 0.4);
      }
      height: fit-content;
      @include media(">=tablet") {
        width: 80% !important;
      }

      &__body {
        width: 100%;
        height: 80%;
      }
      .el-icon-close,
      .el-carousel__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 2em;
        font-weight: bold;
      }
      &__close:hover {
        color: $primary !important;
      }
    }
  }
</style>

<style lang="scss">
  // global style
  .vsl-icon {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 800;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    &.business-card {
      &__call-icon:after {
        content: "\f879";
      }
      &__direction-icon:after {
        content: "\f5eb";
      }
      &__book-online-icon:after {
        content: "\f133";
      }
      &__search-icon:after {
        content: "\f002";
      }
      &__checkbox-icon:after {
        content: "\f0c8";
        font-weight: 400;
      }
      &__checkbox-checked-icon:after {
        content: "\f14a";
        font-weight: 400;
      }
    }
  }
  .font-size-14 {
    font-size: 14px;
    .lm-product__stock {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .margin-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .margin-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
