<template>
  <transition-group name="flip-list">
    <business-card
      v-for="business in businessList"
      :key="business.url_name"
      :isSelected="locationIsSelected(business)"
      :isLocatorEmbeded="isLocatorEmbeded"
      :business="business"
      :closureMessage="closureMessage"
      :pendingOpeningMessage="pendingOpeningMessage"
      :businessCardShowcaseFlags="businessCardShowcaseFlags"
      @handleCardSelection="handleCardSelection"
      @hook:mounted="handleBusinessCardMount(business)"
    ></business-card>
  </transition-group>
</template>

<script>
  import BusinessCard from "./BusinessCard.vue";
  export default {
    components: { BusinessCard },
    props: {
      businessList: {
        type: Array,
        required: false,
      },
      focusedLocation: {
        type: Object,
        required: false,
      },
      closureMessage: {
        type: String,
        required: true,
      },
      pendingOpeningMessage: {
        type: String,
        required: true,
      },
      isLocatorEmbeded: {
        type: Boolean,
        required: true,
      },
      businessCardShowcaseFlags: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        businessesMounted: 0,
        businessCardsMounted: false,
      };
    },
    watch: {
      /**
       * @description Resets businessCardsMounted to false when the businessList data updates. This is
       * required in order for the updateDataLayerEvent to be dispatched when the new list of
       * businesses complete mounting.
       */
      businessList: function () {
        this.businessCardsMounted = false;
      },
      /**
       * @description Dispatches updateDataLayerEvent to attach GTM tagged elements with click handlers if
       * all of the businessCardsMounted is true meaning all are mounted.
       */
      businessCardsMounted: function (allMounted) {
        if (allMounted) {
          document.dispatchEvent(new Event("updateDataLayerEvent"));
        }
      },
    },

    methods: {
      handleCardSelection(location) {
        this.$emit("setFocusedLocation", location);
      },
      // Updates businessCardsMounted based off wheather  the business card business data is
      // the final business in the list of businesses.
      handleBusinessCardMount(business) {
        business?.url_name ==
        this?.businessList[this.businessList.length - 1]?.url_name
          ? (this.businessCardsMounted = true)
          : (this.businessCardsMounted = false);
      },
      locationIsSelected(business) {
        return (
          this.focusedLocation &&
          this.focusedLocation.url_name == business.url_name
        );
      },
      /**
       * @param  {Number} locationIdx index of the selected location
       * @description  Updates the sroll postion to the top and reorders the businessList.
       */
      handleMarkerSelect(locationIdx) {
        // Ensure the scroll is set to the top
        const sideBarContainer = document.getElementById("sidebar-results");
        sideBarContainer.scrollTop = 0;
        if (locationIdx == 0) {
          return; // The location is already at the top.
        }
        if (locationIdx) {
          const targetLocation = this.businessList[locationIdx];
          // Remove the element from the list
          this.businessList.splice(locationIdx, 1);
          // Put the selectedLocation to the top of the list
          this.businessList.splice(0, 0, targetLocation);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .flip-list-move {
    transition: transform 1s;
  }
</style>
