<script>
  import SearchGoogle from "./SearchGoogle.vue";
  import SearchMapbox from "./SearchMapbox.vue";
  export default {
    name: "SearchProvider",
    components: {
      SearchGoogle,
      SearchMapbox,
    },
    props: {
      searchProvider: { required: true, type: String, default: "Google" },
      mapboxProps: {
        // Needed for Mapbox
        type: Array,
        required: false,
      },
      latlon: {
        // Needed for mapbox location biasing
        type: Array,
      },
      searchAddress: {
        type: String,
      },
    },
    computed: {
      selectedSearchProvider() {
        switch (this.searchProvider) {
          case "Mapbox":
            return SearchMapbox;
          default:
            return SearchGoogle;
        }
      },
      locatorH1() {
        const locatorElement = document.querySelector("#locator-h1");
        if (locatorElement && locatorElement.getAttribute("content")) {
          return locatorElement.getAttribute("content");
        }
        return "";
      },
      shouldBeH1() {
        // If on the main locator page, H1. Otherwise H2 (see #3491)
        const metaTag = document.querySelector("#locator-standalone");
        return metaTag && metaTag.getAttribute("content") === "true";
      },
    },
  };
</script>

<template>
  <div class="search">
    <h1 v-if="shouldBeH1">{{ locatorH1 }}</h1>
    <h2 v-else>{{ locatorH1 }}</h2>
    <!-- Transparent wrapper component for any Search Provider -->
    <!-- v-bind="$props" and maps StoreLocator props onto the dynamic component -->
    <!-- v-on="$listeners" maps the dynamic components listeners on the the dynamic component -->
    <component
      :is="selectedSearchProvider"
      v-bind="$props"
      v-on="$listeners"
    ></component>
  </div>
</template>

<style lang="scss" scoped>
  @import "../styles/breakpoints";
  @import "../styles/variables";

  .search {
    background: #ffffff;
    padding: 0 0 1vh 1rem;

    @include media(">=tablet") {
      box-shadow: inset 0 10px 10px #f2f2f2;
      padding: 2.5vh 3rem;
    }

    h1,
    h2 {
      margin-top: 2%;
      font-size: 26px;
    }
  }

  .placeholder-bold::-webkit-input-placeholder {
    color: $primary-text;
    font-weight: 600 !important;
  }
</style>
