<script>
  import { MglPopup } from "vue-mapbox";
  import { isMobile } from "../../../mixins/isMobile";
  import HoursPopover from "../../HoursPopover.vue";
  import { isEmpty } from "lodash";

  export default {
    name: "MapboxPopup",
    components: { MglPopup, HoursPopover },
    mixins: [isMobile],
    props: {
      location: {
        required: true,
        type: Object,
      },
      closureMessage: {
        required: true,
        type: String,
      },
      pendingOpeningMessage: {
        required: true,
        type: String,
      },
    },
    methods: {
      onPopupOpen() {
        this.$refs.popup.popup.setLngLat({
          lng: this.location.lon,
          lat: this.location.lat,
        });
        // Make sure click handlers are attached
        document.dispatchEvent(new Event("updateDataLayerEvent"));
      },
      replaceNewLineWithLineBreak(str) {
        return str.replace("\n", "<br>");
      },
    },
    computed: {
      htmlHours() {
        // stringHrs is a list of lists for the days of the week, where the first day is today.
        // Ex: [[[<today-weekday>, <htmlhours>]], [[<tomorrow-weekday>, <htmlhours>]],...]
        // See lmbrowser.models.Business._build_hours_list
        const todaysOpenData = this.location?.all_opening_hours?.stringHrs[0];
        // Use the first entry for today's data, retuned as [[<weekday>, <htmlhours>]], and extract just the HTML hours.
        if (todaysOpenData) {
          return todaysOpenData[0][1];
        }
        return null;
      },
      openStatusText() {
        if (this.location.all_opening_hours.isOpen === null) {
          return "";
        } else {
          return this.location.all_opening_hours.isOpen ? "Open" : "Open today";
        }
      },
      showDistance() {
        // Distance will be shown as default or if user creates a user store locator config
        // and uncheck display_distance flag
        return (
          this.location.get_storefront_config.display_distance ||
          isEmpty(this.location.get_storefront_config)
        );
      },
    },
  };
</script>
<template>
  <!-- TODO: Consider how customization of popup can be made. Ex: Get object config from backend
  with props for StoreLocatorMap or MapBoxMap that will bind the object key value pairs as props.
  See example in StoreLocatorMap.vue as a Transparent Component -->
  <mgl-popup
    :closeButton="false"
    :coordinates="[location.lon, location.lat]"
    ref="popup"
    @open="onPopupOpen()"
    v-if="!isMobile"
    :offset="[0, -20]"
    :focusAfterOpen="true"
  >
    <template slot="default">
      <div class="popup">
        <div>
          <span v-if="location.business_type_text" class="popup__type">
            <strong>
              {{ location.business_type_text }}
            </strong>
          </span>

          <span class="popup__distance" v-if="showDistance">
            |
            {{ location.distance.toFixed(2) }} mi
          </span>
        </div>
        <span class="popup__name">
          <strong>{{ location.display_name }}</strong>
        </span>
        <div class="font-size-14" v-if="location.stock_indicator">
          <strong :class="location.stock_indicator.stock_img">
            {{ location.stock_indicator.stock_description }},
          </strong>
          {{ location.stock_indicator.stock_details }}
        </div>
        <template
          v-if="!location.permanently_closed && !location.pending_opening"
        >
          <div class="popup__hours__business-hours" aria-label="Open Hours">
            <hours-popover :business="location">
              <template v-slot:hour>
                <span v-if="htmlHours == 'Closed'">
                  <strong
                    class="popup__hours__status"
                    v-html="htmlHours"
                  ></strong>
                </span>
                <span v-else-if="htmlHours == 'Temporarily Closed'">
                  <img
                    class="popup__closure-icon"
                    src="https://lmbucket0.s3.amazonaws.com/media/global_configs/Icon-UI-Warning_Outlined.png"
                    alt=""
                  />
                  <span v-html="htmlHours"></span>
                </span>
                <span class="popup__hours" v-else>
                  <strong class="popup__hours__status">
                    {{ openStatusText }}
                  </strong>
                  <span v-html="htmlHours"></span>
                </span>
              </template>
            </hours-popover>
          </div>
          <a
            v-if="location.get_directions_link"
            class="popup__address"
            :href="location.get_directions_link"
            target="_blank"
            data-ga="Navigate-To-Store-Locator"
            data-ga-event="hard"
            tabindex="0"
            :data-ga-2="location.external_store_code"
            :data-ga-9="location.url_name"
            :data-ga-10="location.address_postcode"
            :data-ga-11="location.locale.name"
            data-dl2="Locator-Popover-Get-Directions"
            data-dl2-e201="Get-Directions"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="location.dl2_click_id"
          >
            <span
              v-html="replaceNewLineWithLineBreak(location.address_text)"
            ></span>
          </a>
          <span
            v-else
            class="popup__address"
            v-html="replaceNewLineWithLineBreak(location.address_text)"
          ></span>

          <a
            :href="'tel:' + location.contact_context.business_phone_raw"
            class="popup__phone phone-text"
            data-ga="Call-Store-Locator"
            data-ga-event="hard"
            :data-ga-2="location.external_store_code"
            :data-ga-9="location.url_name"
            :data-ga-10="location.address_postcode"
            :data-ga-11="location.locale.name"
            data-dl2="Locator-Popover-Call"
            data-dl2-e201="Call"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="location.dl2_click_id"
          >
            {{ location.contact_context.business_phone_text }}
          </a>
        </template>
        <div
          v-else-if="location.pending_opening"
          class="popup__hours__business-hours pending-opening"
        >
          <img
            class="popup__closure-icon"
            src="https://lmbucket0.s3.amazonaws.com/media/global_configs/Icon-UI-Warning_Outlined.png"
            alt=""
          />
          <strong class="popup__hours__status">
            {{ pendingOpeningMessage }}
          </strong>
        </div>
        <div v-else class="popup__hours__business-hours closed">
          <img
            class="popup__closure-icon"
            src="https://lmbucket0.s3.amazonaws.com/media/global_configs/Icon-UI-Warning_Outlined.png"
            alt=""
          />
          <strong class="popup__hours__status">{{ closureMessage }}</strong>
        </div>
      </div>
    </template>
  </mgl-popup>
</template>

<style lang="scss" scoped>
  @import "../../../styles/breakpoints";
  @import "../../../styles/variables";

  .popup {
    display: flex;
    flex-direction: column;
    width: 100%;
    a {
      color: $primary-text;
      cursor: pointer;
    }
    @include media(">=tablet") {
      margin: 0.5% 2.5%;
    }

    span {
      margin: 0.5% 0;
      @include media(">=tablet") {
        margin: 1% 0;
      }
    }
    hr {
      margin: 0.5rem;
    }
    &__phone {
      font-size: 1em;
      color: $primary-text;
      font-weight: 500;
    }

    &__address {
      color: $primary-text;
      font-weight: 500;
      outline: none;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
    }
    &__hours {
      font-weight: 400;
      &__status {
        color: $primary;
      }
      &__business-hours {
        margin: 1rem 0;
        &:hover,
        &:focus {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    &__hours__business-hours {
      &.closed,
      &.pending-opening {
        &:hover {
          text-decoration: none;
        }
        .popup__hours__status {
          color: #262626 !important;
          font-weight: 400;
        }
      }
    }
    &__type,
    &__distance {
      color: $secondary-text;
      font-size: 13px;
    }
    .popup__closure-icon {
      width: 20px;
      vertical-align: bottom;
    }
  }
  .font-size-14 {
    font-size: 14px;
  }
  .lm-product__stock {
    font-family: "Roboto", "Helvetica Neue", Helvetica, sans-serif !important;
  }
  .popup {
    // prevent ugly outline when marker is focused
    outline: none;
  }
</style>
