<script>
  import { isMobile } from "../mixins/isMobile";
  export default {
    name: "CustomSort",
    mixins: [isMobile],
    props: {
      sortingOptions: {
        type: Array,
      },
      sortDisplayName: {
        type: String,
      },
    },
    methods: {
      onSortClick(sortOption) {
        this.$emit("sortOptionClick", sortOption);
      },
    },
  };
</script>

<template>
  <div class="sort search__tab-label">
    <div
      class="sort__sorts-items"
      ref="sortsItems"
      v-if="sortingOptions.length > 0"
    >
      <b>Sort by</b>
      <slot name="sort-slot">
        <el-dropdown
          slot="sort-slot"
          trigger="click"
          class="s-option-dropdown"
          name="sort-option-slot"
        >
          <span v-if="sortingOptions.length > 0">
            <el-button
              class="sort-option-btn sort-btn"
              type="info"
              :aria-label="'Sort by ' + sortDisplayName"
              aria-expanded="false"
              aria-haspopup="listbox"
            >
              <strong>{{ sortDisplayName }}</strong>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class="sort-options-dropdown-menu"
            name="sort-menu"
            role="menu"
            tabindex="-1"
          >
            <slot :name="sOption" v-for="sOption in sortingOptions">
              <a
                @click="onSortClick(sOption)"
                :data-visual-test="sOption.display_name"
                v-bind:key="sOption.display_name"
                class="dropdown-item-option"
                :data-ga="'locator-sort-' + sOption.url_name"
                data-ga-event="hard"
                data-dl2="Locator-Sort"
                data-dl2-e201="Sort"
                data-dl2-e203="y"
                data-dl2-e210="SortOption"
                :data-dl2-e211="sOption.url_name"
                role="button"
              >
                <el-dropdown-item
                  :name="sOption.display_name"
                  :aria-label="sOption.display_name"
                  role="menuitem"
                >
                  {{ sOption.display_name }}
                </el-dropdown-item>
              </a>
            </slot>
          </el-dropdown-menu>
        </el-dropdown>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "../styles/breakpoints";
  @import "../styles/variables";
  .sort {
    max-width: 100%;
  }
</style>
