<template>
  <div class="business-header__business-main" v-if="images.length">
    <el-carousel
      height="200px"
      indicator-position="none"
      v-if="images.length > 1"
    >
      <el-carousel-item v-for="image in images" :key="image.name">
        <a
          v-if="image.image_clickthrough"
          :href="image.image_clickthrough"
          target="_blank"
        >
          <img :src="image.image_uri_medialink" :alt="image.name" />
        </a>
        <span v-else>
          <img :src="image.image_uri_medialink" :alt="image.name" />
        </span>
      </el-carousel-item>
    </el-carousel>
    <div v-else>
      <a
        v-if="images[0].image_clickthrough"
        :href="images[0].image_clickthrough"
        target="_blank"
      >
        <img :src="images[0].image_uri_medialink" :alt="images[0].name" />
      </a>
      <span v-else>
        <img :src="images[0].image_uri_medialink" :alt="images[0].name" />
      </span>
    </div>
  </div>
</template>

<script>
  import { fetchBanner } from "../api/banner.js";

  export default {
    data() {
      return {
        images: [],
      };
    },
    created() {
      fetchBanner()
        .then((response) => {
          this.images = response.data.images;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  };
</script>

<style scoped>
  button {
    padding: 0;
    border: none;
  }
  img {
    width: 100%;
  }
  ::v-deep .el-carousel__item {
    display: flex;
    align-items: center;
  }
</style>
