/**
 * Adds the `allowedGeolocation` boolean to the component. Defaults to false.
 */

export const allowedGeolocation = {
  data() {
    return { allowedGeolocation: false };
  },
  created() {
    this.updateAllowedGeolocation();
  },
  methods: {
    updateAllowedGeolocation: function () {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/permissions#examples
        this.allowedGeolocation = Boolean(result.state !== "denied");
      });
    },
  },
};
