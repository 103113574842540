import request from "../utils/request";

// set base URL relative to subfolder (ex. example.com/stores/locator/)
const scriptName = document.querySelector('meta[name="script-name"]').content;

export function fetchBanner(params) {
  return request({
    url: scriptName + "/api/get-banner/",
    method: "get",
  });
}
