<script>
  export default {
    name: "AppointmentIframe",
    props: {
      appointmentLink: {
        required: true,
        type: String,
      },
      business: {
        required: true,
        type: Object,
      },
    },
    data() {
      return { isVisible: false };
    },
    computed: {
      slotDefined() {
        return Boolean(this.$slots.default);
      },
    },
  };
</script>

<template>
  <div>
    <a :href="appointmentLink">
      <button
        @click="isVisible = true"
        data-ga="Book-Appointment-Locator"
        data-ga-event="hard"
        :data-ga-2="business.external_store_code"
        :data-ga-9="business.url_name"
        :data-ga-10="business.address_postcode"
        :data-ga-11="business.locale.name"
        data-dl2="Book-Appointment-Locator"
        data-dl2-e201="Book-Appointment"
        data-dl2-e203="y"
        data-dl2-e210="Business"
        :data-dl2-e211="business.dl2_click_id"
      >
        <slot v-if="slotDefined"></slot>
        <span v-else>Appointment</span>
      </button>
    </a>
  </div>
</template>

<style lang="scss" scoped>
  button {
    border: none;
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
  }
  ::v-deep .el-dialog {
    height: 90% !important;
    margin-top: 5vh !important;
    max-width: 650px !important; // TODO: Consider how this can be configured as a customization

    &__body {
      width: 100%;
      height: 95% !important;
      padding: 10px !important;
    }
    &__headerbtn {
      top: 5px !important;
      right: 5px !important;
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
</style>
