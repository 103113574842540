<template>
  <div class="vsl-event">
    <span class="vsl-event__icon-date-wrapper">
      <span
        class="business-card__calendar-icon vsl-icon"
        aria-hidden="true"
      ></span>
      <strong>{{ eventsShowcase.event_date }}</strong>
    </span>
    <span class="vsl-event__title">{{ eventsShowcase.event_title }}</span>
    <span class="vsl-event__business-link">
      <a :href="businessLink">{{ remainingEventLabel }}</a>
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      businessLink: {
        required: true,
        type: String,
      },
      eventsShowcase: {
        required: true,
        type: Object,
        default: () => {},
      },
    },
    computed: {
      remainingEventLabel() {
        return this.eventsShowcase.remaining_events == 0
          ? "see more"
          : `+${this.eventsShowcase.remaining_events} more`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/breakpoints";
  .vsl-event {
    padding: 1rem;
    background: #f8f8f8;
    margin: 1rem 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include media(">=tablet") {
        max-width: 125px;
      }
    }
    &__icon-date-wrapper {
      display: flex;
      align-items: center;
    }
    &__icon-date-wrapper span:first-child {
      padding-right: 5px;
    }
    &__business-link a {
      color: #262626;
      text-decoration: underline;
    }
  }
  .locator-wrapper .vsl-icon,
  .locator-wrapper .vsl-icon:hover {
    border: none !important;
    font-size: 16px;
  }
</style>
