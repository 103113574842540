import request from "../utils/request";

// set base URL relative to subfolder (ex. example.com/stores/locator/)
const scriptName = document.querySelector('meta[name="script-name"]').content;

export function fetchNearbyBusinesses(params, productUrlName) {
  // Determine the URL to use (with a product URL name or not).
  let url = scriptName + "/api/get-nearby-business/";
  if ([undefined, null, ""].includes(productUrlName) === false) {
    url += productUrlName + "/";
  }

  return request({
    url: url,
    method: "get",
    params: params,
    timeout: 20000,
  });
}
