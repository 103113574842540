<template>
  <!-- Transparent wrapper component for any Map Provider -->
  <!-- v-bind="$props" and maps StoreLocator props onto the dynamic component -->
  <!-- v-on="$listeners" maps the dynamic components listeners on the the dynamic component -->
  <component :is="selectedMap" v-bind="$props" v-on="$listeners"></component>
</template>

<script>
  import MapboxMap from "./mapbox/MapboxMap.vue";
  import {
    USA_CENTER_LON_LAT,
    INITIAL_MAP_MAX_ZOOM,
    INITIAL_MAP_MIN_ZOOM,
  } from "./constants.js";

  export default {
    name: "StoreLocatorMap",
    components: { MapboxMap },
    props: {
      mapProvider: { required: true, type: String },
      locations: {
        type: Array,
        required: false,
      },
      businessTypes: {
        type: Array,
        required: false,
      },
      geoJsonStyles: {
        type: Object,
        required: false,
      },
      focusedLocation: { required: false },
      center: { required: false, type: Array },
      searchLatLon: { required: false, type: Array },
      minZoom: {
        type: Number,
        required: false,
        default: INITIAL_MAP_MIN_ZOOM,
      },
      maxZoom: {
        type: Number,
        required: false,
        default: INITIAL_MAP_MAX_ZOOM,
      },
      closureMessage: {
        type: String,
        required: true,
      },
      pendingOpeningMessage: {
        type: String,
        required: true,
      },
      markerColor: {
        required: false,
        type: String,
        default: "#370c4f",
        // TODO How to load this in dynamically? Potential to use computed prop on the StoreLocator
        // component, using a config, provided by the backend
      },
    },
    data() {
      return {
        loading: true,
        mapCenter: USA_CENTER_LON_LAT,
      };
    },
    computed: {
      selectedMap() {
        switch (this.mapProvider) {
          case "Mapbox":
            return MapboxMap;
          default:
            return MapboxMap;
        }
      },
    },
  };
</script>
