import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "core-js/stable";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
import locale from "element-ui/lib/locale/lang/en";

// Use Element UI as a plugin
Vue.use(ElementUI, { locale });

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#store-locator-app");
