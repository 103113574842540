<template>
  <el-checkbox-group v-model="selectedOptionsList">
    <el-checkbox
      v-for="option in dropdownOptions"
      :key="option.url_name"
      :label="option.url_name"
      @change="onFilterClick(dropdownOptions)"
    >
      <span class="selectOptionImage" v-if="option.map_pin_image_link">
        <img :src="option.map_pin_image_link" alt="" />
      </span>
      {{ option.display_name }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
  export default {
    name: "Filters",
    props: {
      dropdownOptions: {
        type: Array,
        required: true,
      },
      selected: {
        type: Array,
        required: false,
        default: () => [], // Initialize with an empty array if value prop is not provided
      },
      clearAll: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        selectedOptionsList: this.selected,
      };
    },
    watch: {
      // this prop increments by 1 everytime a user clicks clear so that it can detect changes and update
      clearAll(newValue, oldValue) {
        if (newValue != oldValue) {
          this.selectedOptionsList = [];
          this.$emit("input", this.selectedOptionsList, this.dropdownOptions);
        }
      },
    },
    methods: {
      onFilterClick(filterOptions) {
        this.$emit("input", this.selectedOptionsList, filterOptions);
      },
      clearFilters() {
        this.selected = [];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/breakpoints";
  @import "../styles/variables";
  .blend {
    color: white;
  }
  .filter-option-item {
    display: flex;
    padding: 1rem;
    text-decoration: none;
    color: $primary-text;
    @include media(">=tablet") {
      padding: 0;
    }

    & .vsl-icon {
      padding: 0 1.6rem;
      color: gray;

      @include media(">=tablet") {
        padding: 0 0.8rem 0 0;
      }
      &.selected {
        color: $primary;
      }
    }

    &:hover .material-icons {
      color: $primary;
      font-weight: bold;
    }
  }
  .el-checkbox {
    display: block;
  }
  ::v-deep .el-checkbox__inner {
    width: 18px;
    height: 20px;
    &:after {
      height: 8px;
      left: 6px;
      top: 4px;
    }
  }
  .selectOptionImage {
    display: inline-block;
    height: 25px;
    text-align: center;
    width: 25px;
    img {
      max-height: 25px;
    }
  }
</style>
