<script>
  // In order for Babel to transpile mapbox-gl, it requires Babel to transpile to a more recent
  // browser targets. (no IE 11). The browser target list is defined in lmweb/package.json
  // See https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
  import Mapbox from "mapbox-gl";
  import MbPopup from "./MapboxPopup.vue";
  import MbGeojsonPopup from "./MapboxGeoJsonPopup.vue";
  import {
    MglMap,
    MglMarker,
    MglNavigationControl,
    MglGeolocateControl,
    MglGeojsonLayer,
  } from "v-mapbox";
  import {
    USA_CENTER_LON_LAT,
    INITIAL_MAP_MAX_ZOOM,
    INITIAL_MAP_MIN_ZOOM,
  } from "../constants";

  const EASE_TO_LOCATIONS_ZOOM = 13;
  const MAP_ANIMATION_DURATION_SHORT = 1000;

  export default {
    name: "MapboxMap",
    components: {
      MglMap,
      MglMarker,
      MbPopup,
      MglNavigationControl,
      MglGeolocateControl,
      MglGeojsonLayer,
      MbGeojsonPopup,
    },
    props: {
      minZoom: {
        type: Number,
        required: false,
        default: INITIAL_MAP_MIN_ZOOM,
      },
      maxZoom: {
        type: Number,
        required: false,
        default: INITIAL_MAP_MAX_ZOOM,
      },
      center: { required: false, type: Array },
      searchLatLon: { required: false, type: Array },
      locations: {
        type: Array,
        required: false,
      },
      geoJsonStyles: {
        type: Object,
        required: false,
      },
      businessTypes: {
        type: Array,
        required: false,
      },
      markerColor: {
        required: false,
        type: String,
        default: "#370c4f",
      },
      focusedLocation: {
        required: false,
        type: Object,
      },
      closureMessage: {
        required: true,
        type: String,
      },
      pendingOpeningMessage: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        loading: true,
        accessToken: null,
        mapStyle: "",
        mostMarkersNotVisible: false,
        rerenderKey: 0,
        percentZoomed: 0,
        locationWithGeoJsonSource: [],
        componentKey: 0,
      };
    },
    created() {
      // Hook the template into Mapbox
      this.mapbox = Mapbox;
    },
    mounted() {
      // Set the accessToken
      this.accessToken = this.getMapboxToken();
      this.mapStyle = this.getMapboxStyle();
    },
    updated() {
      // Close all popups open
      let markers = this.$refs.markers;
      if (markers) {
        markers.forEach((marker) => {
          if (marker.marker.getPopup() && marker.marker.getPopup().isOpen()) {
            marker.togglePopup();
          }
        });
      }

      if (this.focusedLocation && this.$refs.markers.length > 0) {
        // Get the marker associated with the focused location
        let markers = this.$refs.markers.filter(
          (marker) =>
            marker.$attrs.id === this.focusedLocation.external_store_code
        );

        // If a maker is found associated with the focused location,
        // show the popup above the location's marker.
        if (markers.length > 0) {
          let marker = markers[0];
          /**
           * Let the business result list animation finish and for the map to be
           * updated with the new focusedLocation object before toggling popup.
           **/
          setTimeout(() => {
            marker.togglePopup();
          }, 500);
        }
      }
    },
    methods: {
      // Fired after the last frame rendered before the map enters an "idle" state
      handleMapIdle() {
        let markers = this.$refs.markers;
        if (markers) {
          markers.forEach((marker) => {
            // make markers focusable
            const onMarkerElement = marker.marker.getElement();
            if (!onMarkerElement.getAttribute("tabindex")) {
              onMarkerElement.setAttribute("tabindex", "0");
              onMarkerElement.setAttribute("role", "button");
              onMarkerElement.setAttribute(
                "aria-label",
                marker.$attrs["source"]["location"]["display_name"]
              );
              onMarkerElement.setAttribute(
                "has-popup",
                marker.marker.getPopup() ? "true" : "false"
              );
              // add focus event to marker
              onMarkerElement.addEventListener("focus", () => {
                // Add the keydown event listener when the element is focused
                const onKeydown = (event) => {
                  if (event.key === "Enter" || event.keyCode === 13) {
                    this.handleSourceSelect(
                      marker.$attrs.source,
                      marker.$attrs.idx
                    );
                    // focus on the popup when the marker is triggered
                    const popup = document.querySelector(".popup");
                    if (popup) {
                      popup.focus();
                      popup.setAttribute("tabindex", "0");
                    }
                  }
                };
                onMarkerElement.addEventListener("keydown", onKeydown);
              });
            }
          });
        }
      },
      handleMapLoad({ map, component }) {
        // Trigger map resize when mounted to ensure the map canvas is properly sized.
        // The Map fails to track the resize postion on initial mount. Known issue with mapbox-gl
        // https://github.com/mapbox/mapbox-gl-js/issues/3265 4/27/21
        map.resize();

        // StoreLocator needs instance of mapbox to initialize geocoder outside of map instance.
        this.$emit("mapboxProps", [this.accessToken, this.mapbox]);

        // Once the map loads, register the actions to the component
        this.actions = component.actions;

        if (this.locations.length) {
          this.buildLocationObject();
          this.actions
            .easeTo({
              center: this.mapCenter,
              duration: MAP_ANIMATION_DURATION_SHORT,
              zoom: EASE_TO_LOCATIONS_ZOOM,
            })
            .then(() => {
              this.fitMapBounds();
            });
        } else {
          this.actions.jumpTo({
            center: USA_CENTER_LON_LAT,
            zoom: INITIAL_MAP_MIN_ZOOM,
          });
        }
        this.loading = false;
      },
      handleSourceSelect(source, idx) {
        var event = new CustomEvent("storeLocatorMap__pinClick", {
          detail: source.location,
        });
        document.dispatchEvent(event);
        this.$emit("onMarkerSelect", source.location, idx);
      },
      handleMapboxGeolocate({ mapboxEvent }) {
        if (mapboxEvent.coords) {
          this.$emit("onGeolocate", {
            lat: mapboxEvent.coords.latitude,
            lon: mapboxEvent.coords.longitude,
          });
        }
      },
      /**
       * @description Iterates the rerender key in order to trigger the map-pins to re-render
       * and determin the pin size.
       */
      handleZoomEnd() {
        const currentZoom = this.$refs.mglMap?.map
          ? this.$refs.mglMap.map.getZoom()
          : this.minZoom;

        this.percentZoomed = 1 - (this.maxZoom - currentZoom) / this.maxZoom;
        this.rerenderKey++;
      },
      /**
       * @description Checks if less than the majority of map markers are in the map's bounds
       * canvas view and the map zoom level. If some of the markers are out of view
       * this.mostMarkersNotVisible is set to true, making the "search this area" class map__refresh-btn render.
       */
      handleDragEnd({ component }) {
        if (this.$refs.markers) {
          const PERCENT_THRESHOLD = 20;
          const markerInBoundsArr = [];
          const markerLngLats = this.$refs.markers.map((m) => m.marker._lngLat);
          for (const lngLatIdx in markerLngLats) {
            let inBounds = component.map
              .getBounds()
              .contains(markerLngLats[lngLatIdx]);
            markerInBoundsArr.push(inBounds);
          }
          const percentOutView = Math.ceil(
            (1 -
              markerInBoundsArr.filter(Boolean).length /
                markerInBoundsArr.length) *
              100
          );
          // Set mostMarkersNotVisible if more than PERCENT_THRESHOLD% pins are out of view
          if (percentOutView >= PERCENT_THRESHOLD) {
            this.mostMarkersNotVisible = true;
          } else {
            this.mostMarkersNotVisible = false;
          }
        }
      },
      /**
       * @description Captures the map's canvas center and emit's the updated lngLat to
       * the StoreLocator so that the search query and location list will be updated.
       */
      triggerLocationFetch() {
        const updatedlngLat = this.$refs.mglMap.map.transform._center;
        this.$emit("updateResults", updatedlngLat);
        this.mostMarkersNotVisible = false;
      },

      getMapboxToken() {
        // See templates/vue_store_locator/index.html
        // Mapbox token is determined by the server and stored in a meta tag in index.html
        const mapboxTokenMetaTag = document.querySelector("#mapbox-key");
        return mapboxTokenMetaTag && mapboxTokenMetaTag.getAttribute("content");
      },
      getMapboxStyle() {
        // See templates/vue_store_locator/index.html
        // Mapbox style is determined by the server and stored in a meta tag in index.html
        const mapboxStyleMetaTag = document.querySelector("#mapbox-style");
        return mapboxStyleMetaTag && mapboxStyleMetaTag.getAttribute("content");
      },
      /**
       * @description Fits the bounds of the map to contain all of the map markers.
       * Depends on $refs markers and mglMap being defined in order to generate and fit bounds
       */
      fitMapBounds() {
        // Check if map marker or geojson exists
        if (
          ((this.$refs.markers && this.$refs.markers.length) ||
            (this.$refs.geojson && this.$refs.geojson.length)) &&
          this.$refs.mglMap.map
        ) {
          const bounds = new Mapbox.LngLatBounds();
          this.$nextTick().then(() => {
            if (this.$refs.geojson && this.$refs.geojson.length) {
              // Fetch all geojson data sources
              Promise.all(
                this.$refs.geojson.map((geojsonDom) =>
                  fetch(geojsonDom.source.data)
                )
              )
                .then((responses) =>
                  Promise.all(responses.map((res) => res.json()))
                )
                // GeoJson file contains bounding box coordinates that tells us the furthest points
                // the polygon locates
                // We will use these 4 points to extend the bounding box so that all the geojson polygon
                // will get displayed within view
                .then((geojson) => {
                  geojson.forEach((gj) => {
                    if (gj.features[0]) {
                      if (gj.features[0].bbox) {
                        bounds.extend(gj.features[0].bbox);
                      } else {
                        /*
                        If there is no bounding box defined in the GeoJson,
                        each coordinate of the given polygon is used to extend
                        the bounding box.
                        */
                        for (const i in gj.features[0].geometry
                          .coordinates[0]) {
                          bounds.extend(
                            gj.features[0].geometry.coordinates[0][i]
                          );
                        }
                      }
                    }
                  });
                  if (this.$refs.markers)
                    this.$refs.markers.forEach((m) => {
                      bounds.extend(m.marker._lngLat);
                    });
                  this.$refs.mglMap.map.fitBounds(bounds, {
                    padding: 40,
                    maxZoom: EASE_TO_LOCATIONS_ZOOM,
                  });
                });
              // If no geojson, only expand the bounding box to the markers lonlat
            } else if (this.$refs.markers && this.$refs.markers.length) {
              this.$refs.markers.forEach((m) => {
                bounds.extend(m.marker._lngLat);
              });
              this.$refs.mglMap.map.fitBounds(bounds, {
                padding: 40,
                maxZoom: EASE_TO_LOCATIONS_ZOOM,
              });
            }
          });
        }
      },
      // Determines marker size based on if the marker is the focused location and the map's
      // percentZoomed. When the marker is the focused location, returns a larger width.
      getMarkerWidth: function (location) {
        const MIN_ICON_WIDTH = 20;
        const ICON_WIDTH = 30;
        const FOCUSED_LOC_WIDTH = ICON_WIDTH + 8;
        if (
          this.focusedLocation &&
          location.url_name == this.focusedLocation.url_name
        ) {
          return FOCUSED_LOC_WIDTH * this.percentZoomed >= MIN_ICON_WIDTH
            ? FOCUSED_LOC_WIDTH * this.percentZoomed
            : MIN_ICON_WIDTH;
        }
        return ICON_WIDTH * this.percentZoomed >= MIN_ICON_WIDTH
          ? ICON_WIDTH * this.percentZoomed
          : MIN_ICON_WIDTH;
      },
      buildLocationObject() {
        if (this.$refs.geojson) {
          // Remove all geojson from map before rebuilding location objects
          this.$refs.geojson.forEach((layer) => {
            layer.remove();
          });
        }
        // Clear the array
        this.locationWithGeoJsonSource = [];
        // rebuild the array based on updated locations
        this.locations.map((location) => {
          if (location.get_geojson_url) {
            this.locationWithGeoJsonSource.push({
              geoJsonUrl: { data: location.get_geojson_url },
              location: location,
              geoJsonFillLayer: {
                type: "fill",
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                paint: {
                  "fill-color": location.geojson_area_color, // When making styling updates to the layers. Make sure to also update mapbox_business_multi_map.js
                  "fill-opacity": 0.3,
                },
              },
              geoJsonLineLayer: {
                type: "line",
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                paint: {
                  "line-color": location.geojson_area_border_color, // When making styling updates to the layers. Make sure to also update mapbox_business_multi_map.js
                  "line-width": 2,
                },
              },
            });
          } else {
            this.locationWithGeoJsonSource.push({
              geoJsonUrl: { data: null },
              location: location,
            });
          }
        });
      },
      forceRerender() {
        this.componentKey += 1;
      },
    },

    watch: {
      focusedLocation: function (updatedLoc) {
        if (updatedLoc) {
          const locationLonLat = [updatedLoc.lon, updatedLoc.lat];
          if (this.actions) {
            this.actions.easeTo({
              center: locationLonLat,
            });
          }
        }
      },
      locations: function () {
        this.buildLocationObject();
        // Forces the geojson layers to rerender. This is added as part of the key in the template
        // in <MglGeojsonLayer>.
        // if a user tries to search for the same location again, the keys won't be the same and
        // thus, no conflicts when attempting to to destroy and recreate the same geoJsonLayer
        // of the same locations again.
        this.forceRerender();
        // Update the map bounds only when no location is selected and the locations change
        // or there is a filter addition. TODO: Handle when all filters are removed. Possible vuex?
        const isFilterUpdate = Object.keys(this.$route.query).includes(
          "filterbizattrs"
        );
        if (!this.focusedLocation || isFilterUpdate) {
          // Wait for the map pins to mount before fitting the map's bounds
          this.$nextTick().then(() => this.fitMapBounds());
        }
      },
      center: function (lonLatArr) {
        if (this.actions)
          this.actions.easeTo({
            center: lonLatArr,
            duration: MAP_ANIMATION_DURATION_SHORT,
            zoom: EASE_TO_LOCATIONS_ZOOM,
          });
      },
    },
    computed: {
      mapCenter() {
        if (this.center) {
          return this.center;
        }
        // mapCenter is only used when this.locations.length is not 0 (see handleMapLoad)
        return [this.locations[0].lon, this.locations[0].lat];
      },
    },
  };
</script>
<template>
  <mgl-map
    ref="mglMap"
    class="map"
    v-loading="loading"
    :accessToken="accessToken"
    :mapStyle="mapStyle"
    :minZoom="minZoom"
    :maxZoom="maxZoom"
    :trackResize="true"
    :attributionControl="true"
    @load="handleMapLoad"
    @dragend="handleDragEnd"
    @zoomend="handleZoomEnd"
    @idle="handleMapIdle"
  >
    <el-button
      v-show="mostMarkersNotVisible"
      @click="triggerLocationFetch"
      class="map__refresh-btn"
    >
      Search this area
    </el-button>
    <mgl-navigation-control position="top-right" />
    <mgl-geolocate-control
      @geolocate="handleMapboxGeolocate"
      position="top-right"
    />
    <template v-for="(source, idx) in locationWithGeoJsonSource">
      <div
        v-if="source.geoJsonUrl.data"
        :key="source.location.external_store_code + idx + componentKey"
      >
        <MglGeojsonLayer
          type="line"
          :sourceId="`${source.location.external_store_code}-line`"
          :layerId="`${source.location.external_store_code}-line`"
          :source="source.geoJsonUrl"
          :layer="source.geoJsonLineLayer"
          ref="geojson"
          @click="handleSourceSelect(source, idx)"
          :id="`${source.location.external_store_code}-line`"
        ></MglGeojsonLayer>
        <MglGeojsonLayer
          type="fill"
          :sourceId="`${source.location.external_store_code}-fill`"
          :layerId="`${source.location.external_store_code}-fill`"
          :source="source.geoJsonUrl"
          :layer="source.geoJsonFillLayer"
          ref="geojson"
          @click="handleSourceSelect(source, idx)"
          :id="`${source.location.external_store_code}-fill`"
        ></MglGeojsonLayer>
        <Mb-Geojson-Popup
          v-if="focusedLocation"
          :location="focusedLocation"
          :closureMessage="closureMessage"
          :pendingOpeningMessage="pendingOpeningMessage"
        />
      </div>
      <mgl-marker
        v-else
        :key="rerenderKey + idx"
        :coordinates="[source.location.lon, source.location.lat]"
        :color="markerColor"
        @click="handleSourceSelect(source, idx)"
        ref="markers"
        anchor="bottom"
        :id="source.location.external_store_code"
        :source="source"
        :idx="idx"
      >
        <!-- Only override the default slot if there's a custom icon uri  -->
        <img
          v-if="source.location.map_pin_image"
          slot="marker"
          :src="source.location.map_pin_image"
          :width="getMarkerWidth(source.location)"
          alt="Location marker on map"
          data-visual-test="locator-map-pin"
        />
        <mb-popup
          v-if="focusedLocation"
          :location="focusedLocation"
          :closureMessage="closureMessage"
          :pendingOpeningMessage="pendingOpeningMessage"
        />
      </mgl-marker>
    </template>
  </mgl-map>
</template>

<style lang="scss" scoped>
  @import "../../../styles/breakpoints";
  @import "../../../styles/variables";

  .map {
    height: $map-height;
    position: relative;
    @include media(">=tablet") {
      flex-grow: 1;
      width: $tablet-map-width;
      height: $tablet-map-height;
    }
    &__refresh-btn {
      position: absolute;
      left: 0;
      top: 0;
      margin: 2% 40%;
      background-color: white;
      min-width: 6%;
      max-width: 200px;
      box-shadow: 0 2px 10px 0 #9c9c9c;
      border-radius: 22px;
      &:hover {
        color: $primary;
      }
    }
  }
</style>
