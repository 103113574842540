<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  import Vue from "vue";
  import { addAccessibilityToElem } from "./utils/helpers";
  export default {
    name: "App",
    created() {
      Vue.prototype.addAccessibilityToElem = addAccessibilityToElem;
    },
  };
</script>
