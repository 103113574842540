<script>
  export default {
    name: "HoursPopover",
    props: {
      business: {
        required: true,
        type: Object,
      },
    },
    data() {
      return { isVisible: false };
    },
    beforeUpdate() {
      // There is a bug where if the hour slot is focused
      // at some point, it remains focused when clicking away
      // and returning to same popup causing the hours popover
      // to appear in weird spots on the map without it being
      // moused over or focused again.
      const elem = document.activeElement;
      if (elem.id && elem.id == "hours_pop") {
        this.$refs.hour_slot.blur();
      }
    },
    computed: {
      openHours() {
        const allHours = this.business?.all_opening_hours?.stringHrs;
        return allHours.map((dayHours) => {
          const hoursInfo = dayHours[0];
          return {
            day: hoursInfo[0],
            hours: hoursInfo[1],
          };
        });
      },
    },
  };
</script>
<template>
  <el-popover
    placement="bottom-start"
    title="Open Hours:"
    trigger="manual"
    v-model="isVisible"
  >
    <table class="open-hours-table">
      <tr v-for="{ day, hours } in openHours" :key="day">
        <td class="text-right">{{ day }}:</td>
        <td><span v-html="hours"></span></td>
      </tr>
    </table>
    <div
      slot="reference"
      role="button"
      :aria-expanded="isVisible"
      @mouseenter="isVisible = true"
      @mouseleave="isVisible = false"
      @blur="isVisible = false"
      @keydown.escape="isVisible = false"
      @keydown.enter="isVisible = true"
      ref="hour_slot"
      :id="business.url_name + 'hours_pop'"
      aria-haspopup="true"
    >
      <slot name="hour"></slot>
    </div>
  </el-popover>
</template>

<style lang="scss" scoped>
  @import "../styles/variables";

  .open-hours-table {
    color: $primary-text;
    td {
      padding-bottom: 0.3rem;
      &:first-child {
        padding-right: 1rem;
      }
    }
    tr:first-child {
      font-weight: bold;
    }
    .text-right {
      text-align: right;
    }
  }
</style>
