<template>
  <div :class="[isMobile ? 'search__tabs' : 'tabs']">
    <slot name="filter"></slot>
    <slot name="sort"></slot>
  </div>
</template>

<script>
  import { isMobile } from "../mixins/isMobile";
  export default {
    name: "filterAndSort",
    mixins: [isMobile],
  };
</script>

<style lang="scss" scoped></style>
