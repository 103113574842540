<script>
  import { MglPopup } from "v-mapbox";
  import { isMobile } from "../../../mixins/isMobile";
  import HoursPopover from "../../HoursPopover.vue";

  export default {
    name: "MapboxGeojsonPopup",
    components: { MglPopup, HoursPopover },
    mixins: [isMobile],
    props: {
      location: {
        required: true,
        type: Object,
      },
      closureMessage: {
        required: true,
        type: String,
      },
    },
    methods: {
      setCoords() {
        this.$refs.popup.popup.setLngLat({
          lng: this.location.lon,
          lat: this.location.lat,
        });
      },
    },
    computed: {
      htmlHours() {
        // stringHrs is a list of lists for the days of the week, where the first day is today.
        // Ex: [[[<today-weekday>, <htmlhours>]], [[<tomorrow-weekday>, <htmlhours>]],...]
        // See lmbrowser.models.Business._build_hours_list
        const todaysOpenData = this.location?.all_opening_hours?.stringHrs[0];
        // Use the first entry for today's data, retuned as [[<weekday>, <htmlhours>]], and extract just the HTML hours.
        if (todaysOpenData) {
          return todaysOpenData[0][1];
        }
        return null;
      },
      openStatusText() {
        if (this.location.all_opening_hours.isOpen === null) {
          return "";
        } else {
          return this.location.all_opening_hours.isOpen ? "Open" : "Open today";
        }
      },
    },
  };
</script>

<template>
  <!--
    Popover component for Regions/geojsons use.
    This component also uses v-mapbox instead of vue-mapbox for supporting
    popover without a marker.
  -->
  <mgl-popup
    :closeButton="false"
    :coordinates="[location.lon, location.lat]"
    ref="popup"
    @open="setCoords()"
    :showed="true"
    v-if="!isMobile"
    :offset="[0, -20]"
  >
    <template slot="default">
      <div class="popup">
        <div>
          <span v-if="location.business_type_text" class="popup__type">
            <strong>
              {{ location.business_type_text }}
            </strong>
          </span>
        </div>
        <span class="popup__name">
          <strong>{{ location.display_name }}</strong>
        </span>
        <template v-if="!location.permanently_closed">
          <div class="popup__hours__business-hours">
            <hours-popover :business="location">
              <template v-slot:hour>
                <span v-if="htmlHours == 'Closed'">
                  <strong
                    class="popup__hours__status"
                    v-html="htmlHours"
                  ></strong>
                </span>
                <span class="popup__hours" v-else>
                  <strong class="popup__hours__status">
                    {{ openStatusText }}
                  </strong>
                  <span v-html="htmlHours"></span>
                </span>
              </template>
            </hours-popover>
          </div>
          <a
            v-if="location.get_directions_link"
            class="popup__address"
            :href="location.get_directions_link"
            target="_blank"
            data-ga="Navigate-To-Store-Locator"
            data-ga-event="hard"
            tabindex="0"
            :data-ga-2="location.external_store_code"
            :data-ga-9="location.url_name"
            :data-ga-10="location.address_postcode"
            :data-ga-11="location.locale.name"
            data-dl2="Locator-Popover-Get-Directions"
            data-dl2-e201="Get-Directions"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="location.dl2_click_id"
          >
            <span v-html="location.address_text"></span>
          </a>
          <span
            v-else
            class="popup__address"
            v-html="location.address_text"
          ></span>

          <a
            :href="'tel:' + location.contact_context.business_phone_raw"
            class="popup__phone phone-text"
            data-ga="Call-Store-Locator"
            data-ga-event="hard"
            :data-ga-2="location.external_store_code"
            :data-ga-9="location.url_name"
            :data-ga-10="location.address_postcode"
            :data-ga-11="location.locale.name"
            data-dl2="Locator-Popover-Call"
            data-dl2-e201="Call"
            data-dl2-e203="y"
            data-dl2-e210="Business"
            :data-dl2-e211="location.dl2_click_id"
          >
            {{ location.contact_context.business_phone_text }}
          </a>
        </template>
        <div v-else class="popup__hours__business-hours closed">
          <img
            class="popup__closure-icon"
            src="https://lmbucket0.s3.amazonaws.com/media/global_configs/Icon-UI-Warning_Outlined.png"
            alt=""
          />
          <strong class="popup__hours__status">{{ closureMessage }}</strong>
        </div>
      </div>
    </template>
  </mgl-popup>
</template>

<style lang="scss" scoped>
  @import "../../../styles/breakpoints";
  @import "../../../styles/variables";

  .popup {
    display: flex;
    flex-direction: column;
    width: 100%;
    a {
      color: $primary-text;
      cursor: pointer;
    }
    @include media(">=tablet") {
      margin: 0.5% 2.5%;
    }

    span {
      margin: 0.5% 0;
      @include media(">=tablet") {
        margin: 1% 0;
      }
    }
    hr {
      margin: 0.5rem;
    }
    &__phone {
      font-size: 1em;
      color: $primary-text;
      font-weight: 500;
    }

    &__address {
      color: $primary-text;
      font-weight: 500;
      outline: none;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
    }
    &__hours {
      font-weight: 400;
      &__status {
        color: $primary;
      }
      &__business-hours:hover,
      &__business-hours:focus {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &__hours__business-hours.closed {
      &:hover {
        text-decoration: none;
      }
      .popup__closure-icon {
        width: 20px;
        vertical-align: bottom;
      }
      .popup__hours__status {
        color: #262626 !important;
        font-weight: 400;
      }
    }
    &__type {
      color: $secondary-text;
      font-size: 13px;
    }
  }
</style>
