<script>
  export default {
    name: "FilterItemDropdown",
    props: {
      dropdownOptions: {
        type: Array,
        required: true,
      },
      selected: {
        type: Array,
        required: false,
        default: () => [], // Initialize with an empty array if value prop is not provided
      },
      selectTitle: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        internalValue: [], // interalValue is used to track changes from both URL param and click action
      };
    },
    beforeUnmount() {
      if (this.$refs.filterDropdown) {
        const filterDropdown = this.$refs.filterDropdown;
        filterDropdown.$el.removeEventListener(
          "keydown",
          this.announceDropdownOption,
          true // Capturing phase
        );
      }
    },
    mounted() {
      if (!this.selected || !Array.isArray(this.selected)) {
        this.$emit("input", []); // Initialize value prop as an empty array if it's not provided or not an array
      }
      this.internalValue = this.selectedOption.map((option) =>
        option.toString()
      );
      const filterDropdown = this.$refs.filterDropdown;
      const listItemRefs = this.getListItemRefs();
      listItemRefs.forEach((refName) => {
        const ref = this.getVueRef(refName);
        if (ref && ref.$el) {
          ref.$el.setAttribute("role", "option");
          // ensure that each option has a unique id
          ref.$el.setAttribute("id", ref.label + filterDropdown.placeholder);
          ref.$el.setAttribute("aria-label", ref.label);
          ref.$el.setAttribute("aria-selected", "false");
          this.selectedOption.map((selectedOption) => {
            if (selectedOption === ref.value) {
              ref.$el.setAttribute("aria-selected", "true");
            }
          });
        }
      });
      // invoked during the capturing phase of the event propagation (only way to access up/down keydown events)
      filterDropdown.$el.addEventListener(
        "keydown",
        this.announceDropdownOption,
        true
      );
      // https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/examples/menu-button-links/
      const input = filterDropdown.$el.querySelector(".el-input__inner");
      input.setAttribute("aria-haspopup", "true");
      input.setAttribute("aria-expanded", "false");
      input.setAttribute("aria-controls", "dropdownUL");
      input.setAttribute("id", "dropdownInput");
      input.setAttribute("aria-activedescendant", "");
      input.setAttribute("role", "combobox");

      const dropdownUL = document.querySelector(".el-select-dropdown__list");
      if (dropdownUL && dropdownUL instanceof Element) {
        dropdownUL.setAttribute("id", "dropdownUL");
        // indictates that the list allows for the selection of one or more items
        dropdownUL.setAttribute("aria-multiselectable", "true");
        // refers to the element that contains the accessible name for the menu
        dropdownUL.setAttribute("aria-labelledby", "dropdownInput");
      }
    },
    computed: {
      // Set selected option from URL params
      selectedOption: {
        get() {
          return this.selected || [];
        },
        set(newValue) {
          this.$emit("input", newValue);
        },
      },
      longestOptionLength() {
        if (!this.dropdownOptions.length) return 0;
        // Find the length of the longest display name
        return this.dropdownOptions.reduce(
          (max, option) => Math.max(max, option.display_name.length),
          0
        );
      },
    },
    methods: {
      getVueRef(refName) {
        const ref = this.$refs[refName];
        return Array.isArray(ref) ? ref[0] : ref;
      },
      getListItemRefs() {
        return Object.keys(this.$refs).filter((refName) => {
          const ref = this.getVueRef(refName);
          return ref && ref.$el && ref.$el.tagName === "LI";
        });
      },
      getEitherFirstLastItem(isFirst) {
        const listItemRefs = this.getListItemRefs();
        const refName = listItemRefs[isFirst ? 0 : listItemRefs.length - 1];
        return this.getVueRef(refName).$el;
      },
      getCurrentScrolledItem(key) {
        const listItemRefs = this.getListItemRefs();
        let scrolledItem = null;
        let previousItem = null;
        // Find the previous item that was hovered
        listItemRefs.forEach((refName) => {
          const ref = this.getVueRef(refName);
          if (ref.$el.classList.contains("hover")) {
            previousItem = ref.$el;
          }
        });
        // for the first time, if no item is hovered, then scroll to the first or last item
        if (!previousItem) {
          if (key === "ArrowDown") {
            scrolledItem = this.getEitherFirstLastItem(true);
          } else if (key === "ArrowUp") {
            scrolledItem = this.getEitherFirstLastItem(false);
          }
        } else {
          if (key === "ArrowDown") {
            scrolledItem = previousItem.nextElementSibling
              ? previousItem.nextElementSibling
              : this.getEitherFirstLastItem(true);
          } else if (key === "ArrowUp") {
            scrolledItem = previousItem.previousElementSibling
              ? previousItem.previousElementSibling
              : this.getEitherFirstLastItem(false);
          }
        }
        return scrolledItem;
      },
      announceDropdownOption(event) {
        if (["ArrowDown", "ArrowUp"].includes(event.key)) {
          event.preventDefault();
          const scrolledItem = this.getCurrentScrolledItem(event.key);
          this.$refs.filterDropdown.$el
            .querySelector(".el-input__inner")
            .setAttribute(
              "aria-activedescendant",
              scrolledItem.getAttribute("id")
            );
        }
      },
      resetAll() {
        // Clear dropdown
        this.internalValue = [];
        // Clear the selected values from URL
        this.selectOption([]);
      },
      selectOption(selectedValues) {
        const listItemRefs = this.getListItemRefs();
        if (selectedValues.includes("filterReset")) {
          this.resetAll();
          listItemRefs.forEach((refName) => {
            const ref = this.getVueRef(refName);
            if (ref.$el && ref.$el.getAttribute("aria-selected")) {
              ref.$el.setAttribute("aria-selected", "false");
            }
          });
          return;
        }
        this.$emit("input", selectedValues, this.dropdownOptions);
        listItemRefs.forEach((refName) => {
          const ref = this.getVueRef(refName);
          if (selectedValues.includes(ref.value)) {
            ref.$el.setAttribute("aria-selected", "true");
          } else {
            ref.$el.setAttribute("aria-selected", "false");
          }
        });
      },
      visibleChange(visible) {
        const filterDropdown = this.$refs.filterDropdown;
        const input = filterDropdown.$el.querySelector(".el-input__inner");
        input.setAttribute("aria-expanded", visible);
      },
    },
  };
</script>
<template>
  <div class="filter-dropdown">
    <el-select
      v-model="internalValue"
      multiple
      collapse-tags
      :placeholder="selectTitle"
      @change="selectOption"
      ref="filterDropdown"
      @visible-change="visibleChange"
      :aria-label="selectTitle"
    >
      <el-option
        v-for="option in dropdownOptions"
        :key="option.url_name"
        :label="option.display_name"
        :value="option.url_name"
        :ref="option.display_name"
      >
        <div v-if="option.map_pin_image_link">
          <span class="selectOptionImage">
            <img :src="option.map_pin_image_link" alt="" />
          </span>
          {{ option.display_name }}
        </div>
      </el-option>
      <el-option
        class="clearButtons"
        key="filterReset"
        value="filterReset"
        label="filterReset"
        ref="filterReset"
      >
        <button
          :style="{ width: longestOptionLength > 20 ? '45%' : '65%' }"
          @click="resetAll"
        >
          Reset
        </button>
      </el-option>
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
  @import "../styles/breakpoints";

  .filter-dropdown {
    display: inline-flex;
    margin-right: 1rem;
    &__dropdown {
      &__item {
        font-size: 0.9em;
      }
    }
    &__item {
      img {
        margin-right: 2rem;
      }
      display: flex;
      margin: 3%;
      align-items: center;
    }

    button {
      border: none;
      background: transparent;
      display: flex;
      justify-self: center;
      font-weight: bold;
      font-size: 1.2rem;
      margin: 1%;
      padding-left: 1em;
      i {
        align-self: center;
      }
    }
    ::v-deep .el-input {
      &__inner {
        border-radius: 32px;
        border: none;
        box-shadow: 0 1px 2px rgb(60 64 67 / 30%),
          0 1px 3px 1px rgb(60 64 67 / 15%);
        padding-top: 16px;
        padding-bottom: 16px;
        &::-webkit-input-placeholder {
          color: #262626;
          font-size: 15px;
          font-weight: bold !important;
        }
        &::-moz-placeholder {
          color: #262626;
          font-size: 15px;
          opacity: 1;
        }
      }
    }
    ::v-deep .el-select__tags {
      & > span:first-of-type {
        display: flex;
        width: 100%;
        align-items: center;
        overflow: hidden;
        span.el-tag:first-child {
          display: flex;
          max-width: 100%;
          align-items: center;
          overflow: hidden;
        }
        .el-select__tags-text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          width: 100%;
          white-space: nowrap;
        }
        .el-tag__close {
          flex: 0 0 16px;
          top: 1px;
        }
      }
    }
  }
  ::v-deep .selectOptionImage {
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    img {
      max-height: 25px;
    }
  }
  .clearButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    > button {
      border: 1px solid darkgray;
      background: #fff;
      border-radius: 5px;
    }
  }
  ::v-deep .el-tag {
    border-radius: 32px;
    position: relative;
  }
  ::v-deep .el-select {
    width: 157px;
    &__tags {
      padding: 0 0.5rem;
    }
    &__tags-text {
      color: #262626;
    }
    &__caret {
      position: absolute;
      right: 0;
      transition: none !important;
    }
    @include media("<=tablet") {
      &__caret {
        position: absolute;
        right: 0;
      }
    }
  }
</style>
