export const isMobile = {
  data() {
    return { windowWidth: window.innerWidth };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    isMobile: function () {
      const TABLET_WIDTH = 768;
      return this.windowWidth <= TABLET_WIDTH;
    },
  },
};
