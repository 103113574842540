<script>
  import Filters from "./Filters.vue";
  import LocationList from "./LocationList.vue";
  import Search from "./Search.vue";
  import StoreLocatorMap from "./map/StoreLocatorMap.vue";
  import { fetchNearbyBusinesses } from "../api/nearby-businesses";
  import { isMobile } from "../mixins/isMobile";
  import SlidingPagination from "vue-sliding-pagination";
  import CustomSort from "./CustomSort.vue";
  import FilterAndSort from "./FilterAndSort.vue";
  import PromoBanner from "./PromoBanner.vue";
  import FilterItemSelectDropdown from "./FilterItemSelectDropdown.vue";

  const LON_LAT_QUERY_PARAMS = ["lat", "lon"];

  export default {
    name: "StoreLocator",
    components: {
      StoreLocatorMap,
      Search,
      LocationList,
      Filters,
      SlidingPagination,
      FilterItemSelectDropdown,
      CustomSort,
      FilterAndSort,
      PromoBanner,
    },
    mixins: [isMobile],
    data() {
      return {
        businessList: [],
        resultsCount: null,
        currentPage: 1,
        numPages: 1,
        mapCenter: null,
        searchLatLon: null,
        searchAddress: null,
        selectedFilters: false,
        focusedLocation: null,
        loading: false,
        showInitialSearchPrompt: false,
        allowedGeolocation: false,
        showingNearbyLocations: false,
        businessTypes: [],
        businessCardShowcaseFlags: {},
        geoJsonStyles: {},
        sortingOptions: [],
        sortDisplayName: "Recommended",
        sortUrlName: null,
        searchProvider: "",
        mapboxProps: [],
        searchComponent: 0,
        expandedTab: null,
        closureMessage: "",
        pendingOpeningMessage: "",
        selectedOptions: [],
        dropdownFilters: [],
        infoPageId: null,
        parsedUtmParams: {},
        geolocating: false,
        productUrlName: "",
        variantUid: "",
        variantContext: {},
        clear: 0,
        selectedVariantsForDisplay: [],
        // The locator can be placed on a page with variant choices. If it is,
        // then the locator updates with the variant choices (and vice versa).
        needToUpdateWithVariantChoices: false,
        indexableUrlParams: {},
        onIndexablePage: false, // Locator indexed at Locale
        indexableRegionalNpdPage: false, // RPD page
        npdPlaceLat: "",
        npdPlaceLoc: "",
        npdPlaceLon: "",
        npdPlaceNELat: "",
        npdPlaceNELon: "",
        npdPlaceSWLat: "",
        npdPlaceSWLon: "",
        npdProdGenTitle: "",
        /** preserve the query parameters' state to allow keeping parameters hidden from the URL for pages with embedded locator.
        apiQueryParams acts as a storage mechanism for query parameters that we apply to the router's state without appending them directly to the URL.
        **/
        apiQueryParams: {},
        initialLat: null,
        initialLon: null,
        widgetId: null,
        locationUrlName: "",
      };
    },
    created() {
      const indexableUrlElement = document.querySelector(
        "#indexable-url-params"
      );
      if (indexableUrlElement) {
        this.indexableUrlParams = JSON.parse(
          indexableUrlElement.getAttribute("content")
        );
      }
      this.onIndexablePage =
        Object.keys(this.indexableUrlParams).length > 0 ? true : false;

      this.searchProvider = document
        .querySelector("#search-provider")
        .getAttribute("content");
      this.locatorInitialInstructions = document
        .querySelector("#locator-initial-instructions")
        .getAttribute("content")
        .replaceAll("\\n", "\n");

      this.widgetId = document
        .querySelector("#widget-id")
        ?.getAttribute("content");

      this.locationUrlName = document
        .querySelector("#location_url_name")
        ?.getAttribute("content");

      const updateLocatorAndVariantsOnSamePage = document.querySelector(
        "#update-locator-on-variant-choices"
      );
      if (
        updateLocatorAndVariantsOnSamePage !== null &&
        updateLocatorAndVariantsOnSamePage.getAttribute("content") === "true"
      ) {
        this.needToUpdateWithVariantChoices = true;
        // Also, make sure to set the product URL name.
        this.productUrlName = this.$route.params.productUrlName;
        // Also, attach a listener for the 'npd__variantChosen' event, and make
        // an API call when it is received.
        document.addEventListener(
          "npd__variantChosen",
          this.handleChosenVariantEvent
        );
      }

      // Try to get inital lat and lon from the html file
      const initialLat = document
        .querySelector("#locator-initial-lat")
        ?.getAttribute("content");
      const initialLon = document
        .querySelector("#locator-initial-lon")
        ?.getAttribute("content");

      // initialLat and initialLon contains a location's lat and lon to query results from
      this.initialLat = initialLat;
      this.initialLon = initialLon;

      this.npdPlaceLat = document
        .getElementById("npd-place-lat")
        ?.getAttribute("content");
      this.npdPlaceLon = document
        .getElementById("npd-place-lon")
        ?.getAttribute("content");
      this.npdPlaceLoc = document
        .getElementById("npd-place-loc")
        ?.getAttribute("content");
      // placeUrl viewports
      this.npdPlaceNELat = document
        .getElementById("npd-place-ne-lat")
        ?.getAttribute("content");
      this.npdPlaceNELon = document
        .getElementById("npd-place-ne-lon")
        ?.getAttribute("content");
      this.npdPlaceSWLat = document
        .getElementById("npd-place-sw-lat")
        ?.getAttribute("content");
      this.npdPlaceSWLon = document
        .getElementById("npd-place-sw-lon")
        ?.getAttribute("content");
      this.npdProdGenTitle = document
        .getElementById("npd-prod-gen-title")
        ?.getAttribute("content");
      if (
        this.npdPlaceNELat &&
        this.npdPlaceNELon &&
        this.npdPlaceSWLat &&
        this.npdPlaceSWLon
      ) {
        this.indexableRegionalNpdPage = true;
      }
      this.apiQueryParams = this.parseQueryParams();
      // #4473: do not add query params to the URL on initial page load for /:country/:state/:city/
      const skipUpdatingQueryParams =
        this.$route.name === "indexable-locator-home";
      this.parseQueryAndFetchData(skipUpdatingQueryParams);
    },
    methods: {
      handleMapboxProps(mapProps) {
        // Create a new Mapbox Geocoder to use outside of map instance.
        this.mapboxProps = mapProps;
        this.searchComponent += 1;
      },
      /**
       * @description Generates the search query and triggers a API call to get nearby businesses
       * when a query present, otherwise requests for the browser's geoloaction to trigger a nearby
       * search.
       */
      sortOptionClick(sortOptionObj) {
        this.sortDisplayName = sortOptionObj.display_name;
        this.sortUrlName = sortOptionObj.url_name;
        this.apiQueryParams = this.parseQueryParams();
        this.parseQueryAndFetchData();
      },
      parseQueryAndFetchData(skipUpdatingQueryParams = false) {
        const hasLocationQueryParams = LON_LAT_QUERY_PARAMS.every((param) =>
          Object.keys(this.apiQueryParams).includes(param)
        );
        // Only make an API request if there are location query params.
        if (hasLocationQueryParams) {
          this.getNearbyBusinesses(
            this.apiQueryParams,
            skipUpdatingQueryParams
          );
        } else if (
          this.apiQueryParams["skiplocationprompt"] &&
          this.apiQueryParams["skiplocationprompt"].toLowerCase() == "true"
        ) {
          // If skiplocationprompt=true, disable location prompt
          this.allowedGeolocation = false;
          this.showInitialSearchPrompt = false;
          this.apiQueryParams["skiplocationprompt"] = "true";
          if (!this.isLocatorWidget) {
            this.$router.push({ query: this.apiQueryParams });
          }
        } else if (!hasLocationQueryParams) {
          // Ask the user to search for something or get their location.
          this.showInitialSearchPrompt = true;
          this.getGeoLocationAndSearch();
          if (!this.isLocatorWidget) {
            this.$router.push({ query: this.apiQueryParams });
          }
        }
      },
      onSearch(address, lat, lon) {
        this.onIndexablePage = false;
        this.indexableRegionalNpdPage = false;
        this.apiQueryParams = this.parseQueryParams();
        /* Update this.apiQueryParams loc, lat, lon, showall */
        this.apiQueryParams = {
          ...this.apiQueryParams,
          loc: address,
          lat: lat,
          lon: lon,
          showall: false,
        };
        if (lat && lon) {
          this.searchLatLon = [lat, lon];
        }
        if (address) {
          this.searchAddress = address;
        }
        if (this.selectedOptions.length > 0) {
          this.apiQueryParams["locationtypes"] = this.selectedOptions.join(",");
        }
        if (this.infoPageId) {
          this.apiQueryParams["info_page_id"] = this.infoPageId;
        }

        if (!this.isLocatorWidget) {
          this.$router.push({ query: this.apiQueryParams });
        }

        this.showingNearbyLocations = false;
        this.parseQueryAndFetchData();
        this.setFocusedLocation(null);
      },
      // Gets location and trigger's search for the lon lat of the allowed location.
      getGeoLocationAndSearch() {
        // browser supports Geolocation API
        if (navigator.geolocation) {
          const onSuccess = (position) => {
            this.geolocating = true;
            this.onSearch(
              null,
              position.coords.latitude,
              position.coords.longitude
            );
            this.allowedGeolocation = true;
            let locationServicesSuccess = new CustomEvent(
              "locator__locationServicesSuccess"
            );
            document.dispatchEvent(locationServicesSuccess);
          };
          const onError = () => {
            this.allowedGeolocation = false;
            let locationServicesDenied = new CustomEvent(
              "locator__locationServicesDenied"
            );
            document.dispatchEvent(locationServicesDenied);
          };
          // Trigger the browser's prompt
          navigator.geolocation.getCurrentPosition(onSuccess, onError);
        }
      },
      updatePage(pageNum) {
        this.onPaginatorPageChange(pageNum);
      },
      onPaginatorPageChange(pageNumber) {
        this.apiQueryParams = { ...this.apiQueryParams, page: pageNumber };
        if (!this.isLocatorWidget) {
          this.$router.push({ query: this.apiQueryParams });
        }
        this.parseQueryAndFetchData();
        this.setFocusedLocation(null);
        // scroll to top of results container after page selection
        this.$refs.resultsContainer.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      parseQueryParams() {
        this.onIndexablePage =
          Object.keys(this.indexableUrlParams).length > 0 &&
          this.onIndexablePage
            ? true
            : false;
        const queryStringMap = this.$route.query;
        const indxParamObj = this.indexableUrlParams;
        if (this.onIndexablePage && Object.keys(indxParamObj).length > 0) {
          queryStringMap[
            "loc"
          ] = `${indxParamObj["city"]}, ${indxParamObj["state"]}, ${indxParamObj["country"]}`;
          queryStringMap["lat"] = indxParamObj["lat"];
          queryStringMap["lon"] = indxParamObj["lon"];
        }
        const query = {};

        // Iterate through the query parameters and add them to query object
        for (const key in queryStringMap) {
          if (Object.prototype.hasOwnProperty.call(queryStringMap, key)) {
            query[key] = queryStringMap[key];
          }
        }

        if (query["info_page_id"]) {
          this.infoPageId = query["info_page_id"];
        } else if (document.getElementById("info-page-id")) {
          this.infoPageId = document
            .getElementById("info-page-id")
            .getAttribute("content");
        }
        if (this.indexableRegionalNpdPage) {
          query["lat"] = this.npdPlaceLat;
          query["lon"] = this.npdPlaceLon;
          query["loc"] = this.npdPlaceLoc;
          query["nelat"] = this.npdPlaceNELat;
          query["nelon"] = this.npdPlaceNELon;
          query["swlat"] = this.npdPlaceSWLat;
          query["swlon"] = this.npdPlaceSWLon;
        }
        if (this.initialLat && this.initialLon) {
          query["lat"] = this.initialLat;
          query["lon"] = this.initialLon;
        }
        // Updates route url so that the results update according to user's sort option selection.
        if (this.sortUrlName) {
          query["bsort"] = this.sortUrlName;
        }
        if (this.indexableRegionalNpdPage) {
          query["indexableRegionalNpdPage"] = this.indexableRegionalNpdPage;
          query["lat"] = this.npdPlaceLat;
          query["lon"] = this.npdPlaceLon;
          query["loc"] = this.npdPlaceLoc;
          // placeUrl viewports
          query["nelat"] = this.npdPlaceNELat;
          query["nelon"] = this.npdPlaceNELon;
          query["swlat"] = this.npdPlaceSWLat;
          query["swlon"] = this.npdPlaceSWLon;
        }

        // If necessary, set the variantUid into the query.
        if (this.variantUid !== "") {
          query["var"] = this.variantUid;
        }

        if (this.widgetId) {
          query["widget_id"] = this.widgetId;
        }

        if (this.locationUrlName) {
          query["location_url_name"] = this.locationUrlName;
        }
        return query;
      },
      initializeSelectedFilters() {
        /**
         * selectedOptions - dropdowns
         *
         */
        this.apiQueryParams = this.parseQueryParams();
        /**
         * This method iterates through each filter in the dropdownFilters array to determine the selected options for each filter.
         * It checks if the filter's options match the URL parameters based on the 'param' and 'url_name' properties.
         * If the URL parameter for the filter's 'param' exists and contains the 'url_name' value, the option is considered selected.
         * The selected options are then stored in the 'optionsValue' property of the corresponding filter object.
         * If at least one option is selected for a filter, the 'selectedFilters' flag is set to true and display the red dot that indicated
         * that there are active filters
         */
        this.dropdownFilters.forEach((filter) => {
          const selectedOptions = filter.filter_options
            .filter((option) => {
              const paramValue = this.apiQueryParams[option.param];
              return (
                paramValue && paramValue.split(",").includes(option.url_name)
              );
            })
            .map((option) => option.url_name);
          if (selectedOptions.length > 0) {
            this.$set(filter, "optionsValue", selectedOptions);
            this.selectedFilters = true;
          }
        });
      },
      getNearbyBusinesses(query, skipUpdatingQueryParams = false) {
        this.loading = true;
        this.showInitialSearchPrompt = false; // user has started search. Clear prompt
        fetchNearbyBusinesses(query, this.productUrlName)
          .then((response) => {
            if (response?.status == 200 && response?.data) {
              let eventData = {};
              if (response.data?.b_result_count !== undefined) {
                this.resultsCount = response.data.b_result_count;
                eventData.resultsCount = response.data.b_result_count;
              }
              if (response.data?.business_list?.object_list) {
                this.businessList = response.data.business_list.object_list;
                eventData.businessList =
                  response.data.business_list.object_list;
              }
              if (response.data?.business_types) {
                this.businessTypes = response.data.business_types;
              }
              if (response.data?.business_card_showcase_flags) {
                this.businessCardShowcaseFlags =
                  response.data.business_card_showcase_flags;
              }
              if (response.data?.styles) {
                this.geoJsonStyles = response.data.styles;
              }
              if (response.data?.closure_message) {
                this.closureMessage = response.data.closure_message;
              }
              if (response.data?.pending_opening_message) {
                this.pendingOpeningMessage =
                  response.data.pending_opening_message;
              }
              if (response.data?.no_results_config) {
                this.noResultsConfig = response.data.no_results_config;
              }
              if (response.data?.sorting_options) {
                this.sortingOptions = response.data.sorting_options;
                // Uses route url definition of bsort to determine sort type
                if (query["bsort"]) {
                  this.sortUrlName = query["bsort"];
                  this.sortingOptions.forEach((sortOpt) => {
                    if (sortOpt.url_name === query["bsort"]) {
                      // Required to display the correct sorting option when route url includes bsort
                      this.sortDisplayName = sortOpt.display_name;
                    }
                  });
                }
                // On first page load with no sort option in route url, the sortUrlName is set to "recommended".
                if (
                  this.sortUrlName == null &&
                  this.sortingOptions.length > 0
                ) {
                  this.sortUrlName = response.data.sorting_options[0].url_name;
                }
                // When a sorting option is selected, sortUrlName is updated accordingly, and can be added to the query.
                else if (this.sortUrlName && this.sortingOptions.length > 0) {
                  query["bsort"] = this.sortUrlName;
                }
                this.handleRouteQueryAndParams();
                if (!this.isLocatorWidget) {
                  if (!skipUpdatingQueryParams) {
                    this.$router.push({ query: this.apiQueryParams });
                  }
                }
              }
              if (
                Object.keys(query).includes("lat") &&
                Object.keys(query).includes("lon")
              ) {
                // Set the map center to the query lat lon when no locations found
                this.mapCenter = [query.lon, query.lat];
                eventData.mapCenter = [query.lon, query.lat];
                this.searchComponent += 1;
              }

              if (
                response?.data?.business_list?.number &&
                response?.data?.business_list?.paginator?.num_pages
              ) {
                this.currentPage = response.data.business_list.number;
                this.numPages = response.data.business_list.paginator.num_pages;
                eventData.currentPage = response.data.business_list.number;
                eventData.numPages =
                  response.data.business_list.paginator.num_pages;
              } else {
                this.currentPage = 1;
                this.numPages = 1;
                eventData.currentPage = 1;
                eventData.numPages = 1;
              }
              if (response?.data?.dropdown_filters) {
                this.dropdownFilters = response.data.dropdown_filters;
                eventData.filters = response.data.dropdown_filters;
                this.initializeSelectedFilters();
              }
              if (response.data?.variant_context) {
                this.variantContext = response.data.variant_context;
                let variantEvent = new CustomEvent(
                  "businessListApi__finishVariants",
                  {
                    detail: JSON.parse(
                      response.data.variant_context.aggregated_price_context
                    ),
                  }
                );
                document.dispatchEvent(variantEvent);
              }
              // Update showingNearbyLocations
              this.showingNearbyLocations =
                response.data.showing_nearby_locations;

              // dispatch a custom event after API successfully returns for external listeners
              if (this.searchAddress) {
                eventData.searchTerm = this.searchAddress;
              } else {
                const urlParams = new URLSearchParams(window.location.search);
                const lat = urlParams.get("lat");
                const lon = urlParams.get("lon");
                eventData.searchTerm = "geo-located: " + lat + ", " + lon;
              }
              let event = new CustomEvent("storeLocatorBusinessList__finish", {
                detail: eventData,
              });
              document.dispatchEvent(event);
              const reportApiResponseEvent = new CustomEvent(
                "storeLocatorBusinessList__reportResponse",
                {
                  detail: response.data,
                }
              );
              document.dispatchEvent(reportApiResponseEvent);
              var searchEvent = new CustomEvent(
                "storeLocatorSearchBar__searchClick",
                {
                  detail: this.businessList,
                }
              );
              document.dispatchEvent(searchEvent);
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
            document.getElementById("sidebar-results").scrollTo(0, 0);
          });
      },
      setFocusedLocation(location) {
        this.focusedLocation = location;
      },
      /**
       * @param {Object} location Location object from the API
       * @param {Number} idx Index of location in the location list.
       * @description Updates the focused location, and reorders the selected marker's location to
       * the top of the list. Only sets and reorders the list (handleMarkerSelect) when the current
       * incoming location is different than the current focusedLocation
       */
      handleMarkerSelect(location, idx) {
        if (
          !this.focusedLocation ||
          location.url_name !== this.focusedLocation.url_name
        ) {
          this.setFocusedLocation(location);
          this.$refs.locationList.handleMarkerSelect(idx);
        }
      },
      /**
       * @description Make an API call to get nearby businesses.
       * @param lngLat {Object} lngLat Longitude and latitude
       */
      handleUpdateResults(lngLat) {
        this.setFocusedLocation(null);
        this.onSearch(null, lngLat.lat, lngLat.lng);
      },
      handleMapboxGeolocate(lngLat) {
        this.setFocusedLocation(null);
        this.onSearch(null, lngLat.lat, lngLat.lon);
      },
      onExpand(tabName) {
        switch (tabName) {
          case "sort":
            this.expandedTab = "sort";
            break;
          case "filter":
            this.expandedTab = "filter";
            break;
          default:
            this.expandedTab = null;
            return;
        }
      },
      onClose() {
        this.expandedTab = null;
      },
      /**
       * @param {Array} selected - Selected option
       * @param {Object} dropdownOptions - Dropdown options: Location Type, Filters, Others
       */
      onFilterClick(selected, dropdownOptions) {
        this.apiQueryParams = this.parseQueryParams();

        // Check if the `param` value already exists as a key in the accumulator (`acc`)
        /**
         * @param {Object} acc - {filterbizattrs: 'featured-store-with-exclusive-offers'}
         * @param {Object} obj - {
            "url_name": "featured-store-with-exclusive-offers",
            "display_name": "Featured Store with Exclusive Offers",
            "param": "filterbizattrs"
          }
         */
        let result = dropdownOptions.reduce((acc, obj) => {
          if (!acc[obj.param]) {
            // If it doesn't exist, assign the `url_name` value to that key, converting it to a string
            acc[obj.param] = selected.includes(obj.url_name)
              ? obj.url_name.toString()
              : null;
          } else if (selected.includes(obj.url_name)) {
            // If the key exists and is present in the `list` array,
            // append the `url_name` value to the existing comma-separated string
            acc[obj.param] += "," + obj.url_name;
          }
          // Check if the value is `null`, and if so, delete the key from the object.
          // This prevents adding an empty param without value to the URL
          if (acc[obj.param] === null) {
            acc[obj.param] = undefined;
          }
          return acc;
        }, {});
        this.apiQueryParams = { ...this.apiQueryParams, ...result };
        // Reset to the first page of the query when filters have been applied.
        this.apiQueryParams["page"] = 1;
        this.handleRouteQueryAndParams();
        if (!this.isLocatorWidget) {
          this.$router.push({ query: this.apiQueryParams });
        }
        this.parseQueryAndFetchData();
      },
      /**
       * @description Handle a custom event that sends a chosen variant's uid:
       * - set the this.variantUid
       * - make a request to the API to get the appropriate businesses
       * If a prior selection's API call is still active, ignore this change.
       * The UI will be updated to match the selection corresponding to the API
       * call when it returns.
       * @param {Object} event Custom event object for npd__variantChosen
       */
      handleChosenVariantEvent(event) {
        if (this.loading) {
          return;
        }
        this.variantUid = event.detail;
        const selectedVariantsFromEvent = this.variantUid.match(
          /[^|]+\|[^|]+/g // Split on every 2 "|"
        );
        this.selectedVariantsForDisplay = selectedVariantsFromEvent.map(
          (selectedVariantValue) => {
            return this.variantContext.variants_for_display
              .flatMap((d) => d.variant_values) // convert to flat array of items
              .find((item) => item.url_name === selectedVariantValue); // find matching item
          }
        );
        this.selectedVariantsForDisplay =
          this.selectedVariantsForDisplay.filter((obj) => {
            return obj !== undefined; // Filter out any undefined from the array
          });
        this.parseQueryAndFetchData();
      },
      clearPvFilter() {
        /** Handles clearing any product variants displaying on npd page. */
        this.selectedVariantsForDisplay = [];
        // reset variantUid to reload all product variants available
        this.variantUid = "";
        let variantEvent = new CustomEvent(
          "resetNPDProductSelection__clearProductVariantSelection"
        );
        document.dispatchEvent(variantEvent);
        this.parseQueryAndFetchData();
      },
      handleRouteQueryAndParams() {
        /**
         * Handles router navigation by managing query parameters and setting appropriate route names.
         *
         * This method performs several checks to determine the appropriate route name and modifies
         * query parameters accordingly. It ensures that extraneous parameters are removed and the URL
         * remains concise and relevant based on the navigation context.
         *
         * Logic breakdown:
         * 1. If on an indexable regional NPD page (/npd/boston-ma/apple-iphone-15):
         *    - Sets the route name to "regional-npd-indexable-locator".
         *    - Removes parameters related to NPD place from `apiQueryParams`.
         *
         * 2. If on an indexable locator home page (/usa/in/logansport/):
         *    - Sets the route name to "indexable-locator-home".
         *    - Removes the "loc" parameter from `apiQueryParams`.
         *
         * 3. When a search is performed on a NPD page (locator/usa/in/logansport/):
         *    - Sets the route name to "npd-page-locator".
         *
         * 4. When a search is performed on an indexable locator page:
         *    - Sets the route name to "locator-home".
         *
         * Example URLs:
         * - Indexable Regional NPD Page: /npd/boston-ma/apple-iphone-15
         * - Indexable Locator Page: /locator/usa/in/logansport/
         *
         * Notes:
         * - This method ensures that unnecessary parameters are removed to shorten the URL.
         * - Handles updates to route names based on the user's navigation to ensure proper indexing.
         */
        if (this.indexableRegionalNpdPage) {
          this.$router.push({ name: "regional-npd-indexable-locator" });
          const npdPlaceQ = [
            "npdPlaceLat",
            "npdPlaceLon",
            "npdPlaceLoc",
            "nelat",
            "nelon",
            "swlat",
            "swlon",
            "indexableRegionalNpdPage",
          ];
          // The npdPlaceQ is added to the query in parseQueryParams() to Returns a queryset of businesses based on the business filteres applied
          // Then we remove them since they're not needed anymore. This will also reduce the URL length.
          npdPlaceQ.forEach((placeQ) => {
            delete this.apiQueryParams[placeQ];
          });
        } else if (this.onIndexablePage) {
          // #4473: prevent unnecessary navigation
          if (this.$route.name !== "indexable-locator-home") {
            this.$router.push({ name: "indexable-locator-home" });
          }
          delete this.apiQueryParams["loc"];
        } else if (
          this.indexableRegionalNpdPage == false &&
          this.$route.name == "regional-npd-indexable-locator"
        ) {
          this.$router.push({ name: "npd-page-locator" });
        } else if (this.$route.name == "indexable-locator-home") {
          /** User started on an indexed locator page, but have navigated away.
           * Update the URL to be the normal locator URL
           * - onSearch sets this.onIndexablePage to False
           * - This ensures that other pages the embed the VSL don't get their
           * URLs set to the regular VSL URLs (such as the info page + VSL)
           * TODO: how to handle other kinds of indexable pages when they are added?
           */
          this.$router.push({ name: "locator-home" });
        }
      },
      onCheckboxFilterClick() {
        this.clear += 1;
        this.selectedFilters = false;
      },
    },
    computed: {
      showEmptyText: function () {
        return (
          (!this.resultsCount && !this.loading) ||
          (!this.allowedGeolocation && !this.resultsCount)
        );
      },
      resultsCountStores: function () {
        if (this.loading) return "Loading...";
        // A simple and small pluralize.
        if (!this.resultsCount && this.resultsCount !== 0) {
          // If falsy but not 0, assume 0 results.
          return "0 locations found nearby";
        }
        if (this.resultsCount == 1) {
          return "1 location found nearby";
        }
        return `${this.resultsCount} locations found nearby`;
      },
      filtersActive() {
        return this.selectedFilters;
      },
      isLocatorEmbeded() {
        return document.getElementById("locator-standalone") ? false : true;
      },
      // Check for template system locator widget and is used to determine
      // whether we want to append query params in the URL
      isLocatorWidget() {
        return document.getElementById("ts-locator") ? true : false;
      },
    },
  };
</script>

<template>
  <div id="locator-wrapper" class="locator-wrapper">
    <promo-banner v-if="isMobile" class="promo-banner__mobile"></promo-banner>
    <div
      class="store-locator__filter-wrapper"
      v-if="dropdownFilters && !isMobile"
    >
      <template v-for="filters in dropdownFilters">
        <filter-item-select-dropdown
          v-if="filters.filter_options.length >= 1"
          v-bind:key="filters.url_name"
          :dropdownOptions="filters.filter_options"
          :selectTitle="filters.display_name"
          :selected="filters.optionsValue"
          @input="onFilterClick"
        ></filter-item-select-dropdown>
      </template>
    </div>
    <store-locator-map
      mapProvider="Mapbox"
      :locations="businessList"
      :focusedLocation="focusedLocation"
      :center="mapCenter"
      :searchLatLon="searchLatLon"
      :businessTypes="businessTypes"
      :geoJsonStyles="geoJsonStyles"
      :closureMessage="closureMessage"
      :pendingOpeningMessage="pendingOpeningMessage"
      @setFocusedLocation="setFocusedLocation"
      @onMarkerSelect="handleMarkerSelect"
      @updateResults="handleUpdateResults"
      @onGeolocate="handleMapboxGeolocate"
      @search="onSearch"
      @mapboxProps="handleMapboxProps"
    />
    <div class="locator-sidebar">
      <div class="locator-sidebar__results-container" id="sidebar-results">
        <promo-banner v-if="!isMobile"></promo-banner>
        <search
          @search="onSearch"
          :searchProvider="searchProvider"
          :mapboxProps="mapboxProps"
          :key="searchComponent"
          :latlon="mapCenter"
          :searchAddress="searchAddress"
        >
          <h2
            class="locator-sidebar__results-container__result-count"
            aria-live="polite"
          >
            <strong>{{ resultsCountStores }}</strong>
          </h2>
        </search>
        <filter-and-sort v-if="isMobile">
          <template v-if="dropdownFilters.length" slot="filter">
            <div
              v-if="!expandedTab"
              @click="onExpand('filter')"
              class="search__tab-label"
            >
              <span class="material-icons-outlined">filter_alt</span>
              <span
                class="filter-label"
                :class="[filtersActive ? 'active' : '']"
              >
                Filter
              </span>
            </div>
            <div class="filters" v-if="expandedTab === 'filter'">
              <div v-if="isMobile" class="filters__header">
                <span class="material-icons close-icon" @click="onClose">
                  close
                </span>
                <h1>Filters</h1>
                <div
                  class="filters__clear"
                  @click="onCheckboxFilterClick"
                  role="button"
                >
                  Clear
                </div>
              </div>
              <div
                v-loading="loading"
                class="filters__filter-items"
                ref="filterItems"
                v-if="!!dropdownFilters.length"
              >
                <div
                  class="item"
                  v-for="filters in dropdownFilters"
                  :key="filters.display_name"
                >
                  <div class="filters__heading">
                    {{ filters.display_name }}:
                  </div>
                  <template>
                    <filters
                      v-if="filters.filter_options.length >= 1"
                      :key="filters.url_name"
                      :dropdownOptions="filters.filter_options"
                      :selected="filters.optionsValue"
                      @input="onFilterClick"
                      :clearAll="clear"
                    ></filters>
                  </template>
                </div>
              </div>
              <div v-if="isMobile" class="return-btn" @click="onClose">
                See {{ resultsCount }}
                {{ resultsCount === 1 ? "store" : "stores" }}
              </div>
            </div>
          </template>
          <template slot="sort">
            <custom-sort
              :sortingOptions="sortingOptions"
              :sortDisplayName="sortDisplayName"
              @sortOptionClick="sortOptionClick"
            ></custom-sort>
          </template>
        </filter-and-sort>
        <h2
          class="locator-sidebar__results-container__result-count"
          aria-live="polite"
        >
          <strong>{{ resultsCountStores }}</strong>
        </h2>
        <div
          class="locator-sidebar__results-container__business-list"
          ref="resultsContainer"
        >
          <p
            v-if="showInitialSearchPrompt"
            class="locator-sidebar__results-container__result-count--message"
            data-visual-test="locator-search-prompt"
          >
            {{ locatorInitialInstructions }}
          </p>
          <div
            v-else-if="resultsCount > 0"
            class="locator-sidebar__results-overview"
          >
            <filter-and-sort v-if="!isMobile">
              <custom-sort
                slot="sort"
                :sortingOptions="sortingOptions"
                :sortDisplayName="sortDisplayName"
                @sortOptionClick="sortOptionClick"
              ></custom-sort>
            </filter-and-sort>
          </div>
          <template v-if="selectedVariantsForDisplay.length > 0">
            <div class="store-locator__selected-variants">
              <span
                v-for="variant in selectedVariantsForDisplay"
                v-bind:key="variant.url_name"
              >
                {{ variant.value }}
              </span>
              <div class="pv-btn-container">
                <div class="pv-refresh-btn" @click="clearPvFilter()">
                  <i class="far fa-times-circle"></i>
                </div>
                <div data-toggle="modal" data-target="#npdModal">
                  <i class="fa fa-edit"></i>
                </div>
              </div>
            </div>
          </template>
          <div
            class="locator-sidebar__results-container__result-count--message"
            v-if="showingNearbyLocations"
          >
            <div role="alert" class="el-alert el-alert--warning is-light">
              <div>
                <div class="el-alert__wrapper">
                  <i class="el-alert__icon el-icon-warning is-small"></i>
                  <span class="el-alert__title is-bold">
                    {{ noResultsConfig.title }}
                  </span>
                </div>
                <div>
                  <div
                    v-if="noResultsConfig.cta_message"
                    class="el-alert__no-results-cta"
                  >
                    {{ noResultsConfig.cta_message }}
                  </div>
                  <a
                    class="el-alert__button"
                    v-if="
                      noResultsConfig.button_text &&
                      noResultsConfig.no_results_link
                    "
                    :href="noResultsConfig.no_results_link"
                    :style="{
                      background: noResultsConfig.button_color,
                      color: noResultsConfig.button_text_color,
                    }"
                    data-ga="Locator-No-Results-CTA"
                    data-ga-event="hard"
                    data-dl2="Locator-No-Results-CTA"
                    data-dl2-e201="CTA-Button"
                    data-dl2-e203="y"
                    target="_blank"
                  >
                    {{ noResultsConfig.button_text }}
                  </a>
                </div>
                <p class="el-alert__description">
                  {{ noResultsConfig.subtitle }}
                </p>
              </div>
            </div>
          </div>
          <location-list
            v-loading="loading"
            :businessList="businessList"
            :focusedLocation="focusedLocation"
            :closureMessage="closureMessage"
            :pendingOpeningMessage="pendingOpeningMessage"
            :isLocatorEmbeded="isLocatorEmbeded"
            :businessCardShowcaseFlags="businessCardShowcaseFlags"
            @setFocusedLocation="setFocusedLocation"
            ref="locationList"
            class="locator-sidebar__results-container__location-list"
          ></location-list>
        </div>
        <div class="paginator-wrapper" v-if="resultsCount">
          <sliding-pagination
            :current="currentPage"
            :total="numPages"
            :updatePage="updatePage"
            :slidingEndingSize="1"
            :nonSlidingSize="5"
            @page-change="onPaginatorPageChange"
            data-visual-test="locator-pagination"
          ></sliding-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "../styles/breakpoints";
  @import "../styles/variables";

  .mapboxgl-ctrl-geocoder {
    width: 100% !important;
    height: 5.6rem !important;
    padding: 0.2rem 0 0 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: transparent;
    &:focus-within {
      box-shadow: inset 0 0 0 1.5px $primary-text;
    }
  }
  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
    top: 18px !important;
    left: 15px !important;
    cursor: pointer;
    z-index: -100 !important;
  }
  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-loading {
    margin-right: 30px !important;
    margin-top: 10px !important;

    @include media(">=tablet") {
      margin-right: 30px !important;
      margin-top: 10px !important;
    }
  }
  .mapboxgl-ctrl-geocoder--input {
    font-weight: bold;
    padding-left: 30px;
    margin-right: 10px;
    &:focus-visible {
      outline: none;
      color: $primary-text;
      outline-width: 0;
      -webkit-appearance: none;
      -webkit-focus-ring-color: none;
    }
  }
  .mapboxgl-ctrl-geocoder--button {
    top: 10px !important;
    right: 15px !important;
    color: $primary-text;
    @include media(">=tablet") {
      top: 18px !important;
    }
  }
  .placeholder-bold::-webkit-input-placeholder {
    color: $primary-text;
    font-weight: 600 !important;
  }
  .el-alert__title,
  .el-alert__description {
    color: #262626 !important;
    margin: 0 !important;
  }

  .el-alert {
    &__wrapper {
      display: flex;
      align-items: center;
    }
    &__title {
      white-space: normal;
      margin-left: 0.5rem !important;
    }
    &__button {
      white-space: normal;
      padding: 0.5rem;
      margin-top: 1rem;
      display: inline-flex;
    }
    &__no-results-cta {
      font-size: 13px;
      color: #000;
      white-space: normal;
      margin-top: 1rem;
      font-weight: bold;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../styles/breakpoints";
  @import "../styles/variables";
  @import "../styles/mapboxoverrides";

  .color {
    flex-direction: column;
  }

  .locator-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    @include media(">=tablet") {
      flex-direction: row-reverse;
      height: calc(100vh - #{$site-header-height});
    }
  }

  .locator-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media(">=tablet") {
      height: 100%;
      overflow: hidden;
      width: 400px;
      min-width: 400px;
    }

    &__results-overview {
      position: relative;
      display: none;
      justify-content: space-between;
      @include media(">=tablet") {
        background-color: #ffffff;
        display: flex;
        padding: 1.2rem 3rem;
        margin-bottom: 1.2rem;
        justify-content: flex-end;
        min-height: 20px;
      }
    }

    &__results-container {
      background: #f8f8f8;
      width: 100%;
      height: 100%;

      @include media(">=tablet") {
        overflow-y: scroll;
      }
      &__location-list {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__result-count {
        display: none;
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #ffffff;
        @include media(">=tablet") {
          display: block;
          font-size: 1.4rem;
          color: $secondary-text;
        }

        &--message {
          font-size: 1.5rem;
          padding: 0 2rem 2rem 2rem;
          min-height: 25px;
          @include media(">=tablet") {
            min-height: 0;
          }
        }
        &--message .el-alert {
          margin: 3rem 0 0;
        }
      }

      &__business-list {
        max-height: 80vh;
        overflow-y: auto;
      }
    }

    .desktop-filters {
      display: block;
    }
  }

  .paginator-wrapper {
    width: 100%;
    padding: 10px 0;
    background: #ffffff;
    text-align: center;
    position: sticky;
    bottom: 0;
  }

  @mixin a-styled() {
    a {
      color: white;
      text-decoration: none !important;
    }
  }

  :deep(.c-sliding-pagination__list-element) {
    border-radius: 0px;
    background-color: #f2f2f3;
    margin: 3px 5px;
    padding: 5px 10px;

    a {
      color: #55575b;
    }

    &:active,
    &:hover {
      border-radius: 0px;
      font-weight: 800;
      color: white;
      background-color: $primary;
      @include a-styled();
    }

    &.c-sliding-pagination__page--current,
    &.c-sliding-pagination__list-element--active {
      background-color: $primary;
      @include a-styled();
    }
  }
  .search {
    &__tabs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem 2.5rem;
      margin-bottom: 1rem;
      min-height: 36.75px;
      background-color: #ffffff;
      @include media(">=tablet") {
        display: none;
      }
      & > div {
        &:not(:first-of-type) > div {
          // Make sure that all non-first tabs have no left border.
          border-left: none;
        }
      }
    }

    &__tab-label {
      font-weight: bold;
      cursor: pointer;
      border-top: none;
      font-size: 14px;
      color: $primary-text;
      @include media(">=tablet") {
        margin-top: 0;
        margin-right: 0;
      }

      .material-icons-outlined {
        font-size: 1.1em;
      }

      & .fa-caret-down {
        display: block;
        float: right;
        margin-right: 1rem;
        line-height: inherit;
      }
    }
  }
  .filter-label.active::after,
  .sort-label.active::after {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: $primary;
    border-radius: 100%;
    margin-left: 0.5rem;
  }
  .promo-banner__mobile {
    margin-bottom: 0.25rem;
  }
  .locator-sidebar__results-container__result-count--message {
    white-space: pre-line;
  }

  .mini-device-info {
    display: none;
    @include media(">=tablet") {
      right: 3%;
      bottom: 8%;
      background: #fff;
      height: 150px;
      width: 150px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 9rem 1rem;
      font-size: 12px;
      &__brand {
        font-weight: bold;
        padding-bottom: 1rem;
      }
    }
  }
  .store-locator {
    &__selected-variants {
      display: flex;
      column-gap: 1rem;
      margin: 1.2rem 3rem;
      align-items: stretch;
      > span {
        border: 1px solid #000;
        padding: 0.5rem;
        border-radius: 6px;
        align-items: center;
        display: flex;
      }
      div:last-child {
        margin-left: auto;
        align-items: center;
        display: flex;
      }
      .pv-btn-container > div {
        margin-left: 1.4rem;
        cursor: pointer;
      }
    }
    &__filter-wrapper {
      margin: 0.5em 0 0 1.2em;
      position: absolute;
      left: 400px;
      z-index: 1;
      top: 3%;
    }
  }
  .filters {
    position: fixed; // Allows the filter options to float above the results list.
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 2000;
    // z-index must be higher than el-loading so the filters are never obscured.

    @include media(">=tablet") {
      position: static;
      background: none;
    }

    &__header {
      padding-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 0 2.4rem;
      color: $primary-text;
      h1 {
        font-size: 1.6em;
      }
      & .close-icon {
        cursor: pointer;
        color: $primary-text;
      }
      .material-icons {
        font-size: 1.7rem;
      }
    }

    &__top {
      display: block;
      font-weight: bold;
      color: $primary-text;
      margin-bottom: 1.2rem;
    }
    &__filter-items {
      font-size: 1.6rem;
      margin: 5%;
      .item {
        margin: 2rem 0;
      }
      @include media(">=tablet") {
        font-size: 1.4rem;
        margin: unset;
        cursor: pointer;
      }
    }
    &__clear {
      cursor: pointer;
    }
    &__heading {
      padding-bottom: 1rem;
    }
  }
  .return-btn {
    position: absolute;
    bottom: 3.6rem;
    left: 10%;
    right: 10%;
    text-align: center;
    color: $primary-text;
    font-size: 1.8rem;
    cursor: pointer;
    padding: 1.6rem 0;
    margin: 0 auto;
    border: 1px solid $primary-text;
    &:hover {
      text-decoration: none;
      border: 1px solid $primary;
    }
  }
</style>
